import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Card, Typography, Space, Table, Button, Tag, Image } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import { useAppDispatch } from 'state'
import { getOffers } from 'state/offer'
import { useSelector } from 'react-redux'
import InfluencerOfferModal from '../InfluencerOfferModal'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const OfferList = () => {
  const dispatch = useAppDispatch()
  const offer = useSelector((state) => get(state, 'offer'))
  const [offerSelected, setOfferSelected] = useState<any>()
  const [isModalOfferOpen, setModalOfferOpen] = useState(false)

  useEffect(() => {
    dispatch(getOffers())
  }, [])

  const handleOpenOfferCampaign = (offer) => {
    setOfferSelected(offer)
    setModalOfferOpen(true)
  }

  const handleCloseOfferCampaign = () => {
    setOfferSelected(null)
    setModalOfferOpen(false)
  }

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'campaignOffer',
      key: 'campaignOffer',
      render: (record) => <span>#{record?.id}</span>,
    },
    {
      title: 'Campaign',
      dataIndex: 'campaign',
      key: 'campaign',
      render: (record) => (
        <div>
          <Space>
            <Image width={50} src={`https://api.agiatot.vn/images/${record?.mainImage}`} />
            <div>
              <div style={{ lineHeight: '1.2' }}>{record?.name}</div>
              <div>
                <Image preview={false} width={16} src="/images/icon-instagram.svg" />
              </div>
            </div>
          </Space>
        </div>
      ),
    },
    {
      title: 'Influencer',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (record) => (
        <div>
          <Space>
            <Image
              width={40}
              style={{ borderRadius: '50%' }}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
            <div>{record?.nickName}</div>
          </Space>
        </div>
      ),
    },

    {
      title: 'Amount',
      dataIndex: 'campaignOffer',
      key: 'campaignOffer',
      render: (record) => <span style={{ color: '#7645d9' }}>{record?.offerPoint || 0}P</span>,
    },
    {
      title: 'Status',
      dataIndex: 'campaignOffer',
      key: 'campaignOffer',
      render: (record) => {
        if (record?.status == 1) {
          return <Tag color="default">Under offer</Tag>
        }
        if (record?.status == 2) {
          return <Tag color="success">Accepted</Tag>
        }
        if (record?.status == 3) {
          return <Tag color="warning">Refused</Tag>
        }
        if (record?.status == 4) {
          return <Tag color="error">canceled</Tag>
        }
        return <Tag color="processing">expired</Tag>
      },
    },
    {
      title: 'Reason for refusal',
      dataIndex: 'campaignOffer',
      key: 'campaignOffer',
      render: (record) => <span>{record?.refuseMessage}</span>,
    },
    {
      title: 'Date',
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: (creationTime) => <span>01/08/2023</span>,
    },
    {
      title: '',
      key: '',
      width: '100px',
      align: 'center',

      render: (_, record) => (
        <Space>
          <Button type="primary" ghost onClick={() => handleOpenOfferCampaign(record)}>
            View Offer
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Offer List
          </Typography.Title>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card bordered={false}>
            {offer && offer.data && <Table columns={columns} dataSource={offer.data.items} rowKey="id" />}
          </Card>
        </Wrapper>
      </Container>
      <InfluencerOfferModal data={offerSelected} isOpen={isModalOfferOpen} onClose={handleCloseOfferCampaign} />
    </Layout>
  )
}

export default OfferList
