import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import get from 'lodash/get'
import { Card, Table, Input, Space, Image, Button, Typography, Tag } from 'antd'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { PlusOutlined } from '@ant-design/icons'
import { Youtube, Facebook, Instagram } from 'iconsax-react'
import { useAppDispatch } from 'state'
import { getCampaigns } from 'state/campaign'
import { useSelector } from 'react-redux'

import data from './data'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const CampaignList = () => {
  const dispatch = useAppDispatch()
  const campaign = useSelector((state) => get(state, 'campaign.data'))

  useEffect(() => {
    dispatch(getCampaigns({}))
  }, [])

  const columns: any = [
    {
      title: 'Campaign',
      key: 'campaign',
      render: (_, record) => (
        <div>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <div>
              <Image
                style={{ borderRadius: '10px', overflow: 'hidden', objectFit: 'cover' }}
                width={80}
                height={80}
                src={`https://api.agiatot.vn/images/${record.mainImage}`}
              />
            </div>
            <div>
              <h4
                style={{
                  fontWeight: 500,
                  fontSize: '1rem',
                  maxWidth: '400px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {record.name}
              </h4>
              <div>
                {!!record.chanelTiktok && <Instagram size="20" variant="Outline" />}
                {!!record.chanelFacebook && <Facebook size="20" variant="Outline" />}
                {!!record.changelYoutube && <Youtube size="20" variant="Outline" />}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'state',
      key: 'state',
      render: (state) => {
        let text = ''
        let color = ''
        if (state === 1) {
          color = 'success'
          text = 'Completed'
        } else if (state === 2) {
          color = 'green'
          text = 'Approved'
        } else if (state === 3) {
          color = 'processing'
          text = 'Applying'
        } else if (state === 4) {
          color = 'warning'
          text = 'Draft Submit'
        } else {
          color = 'default'
          text = 'Registered'
        }
        return <Tag color={color}>{text}</Tag>
      },
    },
    {
      title: 'Ngày',
      key: 'date',
      render: (_, record) => <div>2023.07.13 - 2023.08.06</div>,
    },
    {
      title: 'Recruit',
      key: 'recruiment',
      render: (_, record) => <div>{record.limit}</div>,
    },
    {
      title: 'Apply',
      key: 'apply',
      render: (_, record) => <div>{record.requested_count || 0}</div>,
    },

    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Space>
          <Link to={`/campaigns/${record.id}/management`}>
            <Button type="link" ghost>
              View
            </Button>
          </Link>
          <Link to={`/campaigns/${record.id}/edit`}>
            <Button type="link" ghost>
              Edit
            </Button>
          </Link>
        </Space>
      ),
    },
  ]

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Campaigns
          </Typography.Title>
          <div>
            <Link to="/campaigns/add">
              <Button type="primary" ghost icon={<PlusOutlined rev={undefined} />}>
                Thêm mới
              </Button>
            </Link>
          </div>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card>
            <Table columns={columns} dataSource={campaign?.items || []} />
          </Card>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default CampaignList
