import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};
    overflow: hidden;
  }
  * {
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'
  }
  a {
    color: #7645D9;
  }
  .ant-btn {
    height: 38px
  }
  /* form */
  .ant-input,
  .ant-picker {
    padding: 7px 12px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 38px;
    padding-top: 3px;
  }
  .ant-form-item-control-input {
    min-height: 38px;
  }
  .ant-radio-button-wrapper {
    height: 38px;
  }
  .ant-input-number-input {
    height: 38px;
  }
  .ant-form-item .ant-form-item-label >label {
    height: 38px;
  }
  .ant-form-vertical  .ant-form-item .ant-form-item-label >label { 
    height: auto
  }
  .ant-form-item .ant-form-item-control-input {
    min-height: 38px;
  }
  .ant-form-item .ant-form-item-label {
    text-align: left;
  }
  .form-upload-item.ant-form-item .ant-form-item-label >label {
    margin-top: 30px;
  }
  
`

export default GlobalStyle
