import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Card, Typography, Space, Table, Button, Tag } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import { useAppDispatch } from 'state'
import { getBrandList } from 'state/brand'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const BrandList = () => {
  const dispatch = useAppDispatch()
  const brand = useSelector((state) => get(state, 'brand'))

  useEffect(() => {
    dispatch(getBrandList())
  }, [])

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Tên công ty',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      render: (text) => <span style={{ textTransform: 'capitalize' }}>{text}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isLockout',
      key: 'isLockout',
      render: (isLockout) => {
        if (isLockout) {
          return <Tag color="error">Blocked</Tag>
        }
        return <Tag color="success">Active</Tag>
      },
    },
    {
      title: 'Date',
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: (creationTime) => <span>01/08/2023</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: '100px',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Button type="link" danger>
            Khoá
          </Button>
          <Link to={`/brands/${record.id}/edit`}>
            <Button type="link">Sửa</Button>
          </Link>
        </Space>
      ),
    },
  ]

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Thương hiệu
          </Typography.Title>
          <div>
            <Link to="/brands/add">
              <Button type="primary" ghost icon={<PlusOutlined rev={undefined} />}>
                Thêm mới
              </Button>
            </Link>
          </div>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card bordered={false}>
            {brand && brand.data && <Table columns={columns} dataSource={brand.data.items} rowKey="id" />}
          </Card>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default BrandList
