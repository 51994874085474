import styled from 'styled-components'

export const StyledFormLabel = styled.div`
  font-weight: 500;
  margin-bottom: 5px;
`

export const StyledFormItem = styled.div`
  margin-bottom: 15px;
`
