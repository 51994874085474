import { Request } from 'helpers/request'
import { Api } from 'config/api'

const serverBaseUrl = process.env.REACT_APP_API_URL

export const getCategoryComboBoxApi = () => {
    return Request.call({
        url: Api.GET_COMBOBOX_CATEGORY,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}
