import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import moment from 'moment'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import { unwrapResult } from '@reduxjs/toolkit'
import { Link, useParams, useHistory } from 'react-router-dom'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Input, Typography, Card, Button, Space, Form, Divider, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import CampaignInfo from './CampaignInfo'
import InfluencerInfo from './InfluencerInfo'
import ProductInfo from './ProductInfo'
import Schedule from './Schedule'
import { useAppDispatch } from 'state'
import { addCampaign, getCampaignDetail } from 'state/campaign'
import { getBrandComboBox } from 'state/brand'
import { getCategoryComboBox } from 'state/category'
import { useSelector } from 'react-redux'

import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(weekday)
dayjs.extend(localeData)

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`
const AddCampaign = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  let { campaignId } = useParams<any>()
  const [loading, setLoading] = useState(false)

  const campaignDetail: any = useSelector((state) => get(state, 'campaign.detail', null))

  useEffect(() => {
    dispatch(getBrandComboBox({}))
    dispatch(getCategoryComboBox({}))
  }, [])

  useEffect(() => {
    if (campaignId) {
      dispatch(getCampaignDetail({ campaignId }))
    }
  }, [campaignId])

  useEffect(() => {
    if (campaignDetail && campaignId) {
      const data = {
        ...campaignDetail,
        categoryId: `${campaignDetail.categoryId}`,
        brandId: `${campaignDetail.brandId}`,
        applyStartDate: dayjs(campaignDetail['applyStartDate']),
        applyEndDate: dayjs(campaignDetail['applyEndDate']),
        selectStartDate: dayjs(campaignDetail['selectStartDate']),
        selectEndDate: dayjs(campaignDetail['selectEndDate']),
        postStartDate: dayjs(campaignDetail['postStartDate']),
        postEndDate: dayjs(campaignDetail['postEndDate']),
        evaluationStartDate: dayjs(campaignDetail['evaluationStartDate']),
        evaluationEndDate: campaignDetail['evaluationEndDate'] ? dayjs(campaignDetail['evaluationEndDate']) : null,
        media: campaignDetail.chanelFacebook
          ? 'facebook'
          : campaignDetail.chanelInstagram
          ? 'instagram'
          : campaignDetail.chanelTiktok
          ? 'tiktok'
          : campaignDetail.chanelYoutube
          ? 'youtube'
          : '',
      } as any

      form.setFieldsValue(data)
    }
  }, [campaignDetail, campaignId])

  const [fileList, setFileList] = useState<any[]>([])

  const propsUpload: any = {
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])

      return false
    },
    fileList,
  }

  const handleUploadImage = async () => {
    const formData = new FormData()
    fileList.forEach((file) => {
      formData.append('file', file as any)
      formData.append('imageType', 'campaign')
    })

    // You can use any AJAX library you like
    return fetch(`${process.env.REACT_APP_API_URL}/File/Upload`, {
      method: 'POST',
      body: formData,
    }).then((res) => res.json())
  }

  const handleSubmitForm = async (values: any) => {
    const resUpload = await handleUploadImage()
    setLoading(true)
    let data = {
      ...values,
      mainImage: get(resUpload, 'result.fileName', ''),
      applyStartDate: values['applyStartDate'].format('YYYY-MM-DDTHH:mm:ss'),
      applyEndDate: values['applyEndDate'].format('YYYY-MM-DDTHH:mm:ss'),
      selectStartDate: values['selectStartDate'].format('YYYY-MM-DDTHH:mm:ss'),
      selectEndDate: values['selectEndDate'].format('YYYY-MM-DDTHH:mm:ss'),
      postStartDate: values['postStartDate'].format('YYYY-MM-DDTHH:mm:ss'),
      postEndDate: values['postEndDate'].format('YYYY-MM-DDTHH:mm:ss'),
      evaluationStartDate: values['evaluationStartDate'].format('YYYY-MM-DDTHH:mm:ss'),
      evaluationEndDate: values['evaluationEndDate'].format('YYYY-MM-DDTHH:mm:ss'),
      chanelFacebook: values.media == 'facebook' ? true : false,
      chanelInstagram: values.media == 'instagram' ? true : false,
      chanelYoutube: values.media == 'youtube' ? true : false,
      chanelTiktok: values.media == 'tiktok' ? true : false,
    }

    if (!!campaignDetail?.id) {
      data = {
        ...campaignDetail,
        ...data,
      }
    }

    dispatch(addCampaign(data))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.success) {
          setLoading(false)
          if (!!campaignDetail?.id) {
            message.success('Cập nhật campaign thành công!')
          } else {
            message.success('Thêm campaign thành công!')
          }
          history.push('/campaigns')
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            {!!campaignDetail?.id ? `Chiến dịch` : `Tạo chiến dịch`}
          </Typography.Title>
          <div>
            <Link to="/campaigns">
              <Button type="primary" ghost icon={<ArrowLeftOutlined rev={undefined} />}>
                Quay lại
              </Button>
            </Link>
          </div>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 10 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmitForm}
              autoComplete="off"
            >
              <CampaignInfo propsUpload={propsUpload} />
              <Divider />
              <br />
              <InfluencerInfo />
              <Divider />
              <br />
              <ProductInfo />
              <br />
              <Divider />
              <br />
              <Schedule />
              <Divider />
              <br />
              <div style={{ textAlign: 'center' }}>
                <Space>
                  <Button loading={loading} type="primary" htmlType="submit" size="large" style={{ minWidth: '100px' }}>
                    Lưu
                  </Button>
                  <Link to="/campaigns">
                    <Button htmlType="button" size="large" style={{ minWidth: '100px' }}>
                      Huỷ
                    </Button>
                  </Link>
                </Space>
              </div>
            </Form>
          </Card>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default AddCampaign
