import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Avatar, Layout, Space, Dropdown, Input, Badge, Menu, Button } from 'antd'
import type { MenuProps } from 'antd'
import { ArrowDown2 } from 'iconsax-react'
import { useSelector } from 'react-redux'
// import Footer from 'components/Footer'
import { logout } from 'state/auth'
import { profileClear } from 'state/profile'
import { useAppDispatch } from 'state'
import menus from 'config/menu'
import get from 'lodash/get'
import { getProfile } from 'state/profile'
import DropdownLanguage from './DropdownLanguage'

const { Header, Content, Sider } = Layout

const StyledContainer = styled.div`
  width: 100%;
  padding: 0;
`

interface Props {
  children: React.ReactNode
}

const LayoutAuth: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const profile = useSelector((state) => get(state, 'profile', null))

  const profileName = get(profile, 'data.name', '')
  const userData = profile.data || null
  const dispatch = useAppDispatch()
  const accessToken = useSelector((state) => get(state, 'auth.accessToken'))

  // useEffect(() => {
  //   dispatch(getProfile({}))
  // }, [])

  useEffect(() => {
    if (!accessToken) {
      window.location.replace('/#/login')
    }
  }, [accessToken])

  const handleLogout = () => {
    dispatch(logout()).then((response: any) => {
      dispatch(profileClear())
    })
  }

  const items: MenuProps['items'] = [
    {
      key: 'vi',
      label: <Link to="/my-profile">Sửa Profile</Link>,
    },
    {
      key: 'logout',
      label: (
        <button
          type="button"
          onClick={handleLogout}
          style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}
        >
          Đăng xuất
        </button>
      ),
    },
  ]

  const pathArray = location.pathname ? location.pathname.split('/') : []

  return (
    <Layout className="layout" style={{ width: '100%' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#fff',
          borderBottom: '2px solid rgb(230, 235, 245)',
          position: 'fixed',
          top: 0,
          zIndex: 99,
          width: '100%',
          padding: '0 32px',
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '30px',
            }}
          >
            <div
              style={{
                lineHeight: 1,
              }}
            >
              <Link to="/">
                <img
                  src="/images/logo.svg"
                  alt=""
                  style={{
                    height: '40px',
                  }}
                />
              </Link>
            </div>
            {/* <div>
              <div>
                <div style={{ position: 'relative' }}>
                  <Input
                    placeholder="Tìm chiến dịch"
                    size="large"
                    style={{
                      width: 300,
                      height: '40px',
                      padding: '0 10px 0 40px',
                      background: '#f5f7fc',
                      border: 'none',
                    }}
                  />
                  <div style={{ position: 'absolute', left: '10px', top: '2px' }}>
                    <SearchOutlined style={{ color: '#222', fontSize: '20px' }} rev={undefined} />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div>
          <Space
            size="large"
            style={{
              lineHeight: 1,
              height: '40px',
            }}
          >
            <Space style={{ cursor: 'pointer' }}>
              <Avatar shape="square" size={30} src={<img src="/images/pancakeswap.png" alt="vi" />} />
              <div style={{ paddingTop: '1px', display: 'inline-flex' }}>
                <span> {profileName} </span>
              </div>
              {/* <div style={{ paddingTop: '4px' }}>
                <ArrowDown2 size="20" color="#ccc" />
              </div> */}
            </Space>

            <span style={{ color: '#ccc' }}>|</span>
            <button
              type="button"
              onClick={handleLogout}
              style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}
            >
              Logout
            </button>
            {/* <DropdownLanguage /> */}
          </Space>
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{
            background: '#fff',
            overflow: 'auto',
            height: 'calc(100vh - 64px)',
            position: 'fixed',
            left: 0,
            top: '64px',
            bottom: 0,
            boxShadow: '0 2px 20px 0 rgba(0,0,0,.15)',
            paddingTop: '10px',
          }}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[pathArray[1]]}
            // defaultOpenKeys={['sub1']}
            selectedKeys={[pathArray[1]]}
            style={{ height: '100%', borderRight: 0 }}
            items={menus}
          />
          <div
            style={{
              position: 'absolute',
              bottom: '0',
              left: 0,
              padding: '10px 20px',
              textAlign: 'left',
              fontSize: '0.625rem',
              color: '#606778',
            }}
          >
            <div>
              <p style={{ marginBottom: '8px' }}>
                <b>ViralWork Co., Ltd</b>
              </p>
              <p style={{ marginBottom: '5px' }}>
                Address: 3rd floor, Tower A, Viettel Building, 285 Cach Mang Thang Tam, Ward 12, District 10, City. Ho
                Chi Minh
              </p>
              <p style={{ marginBottom: '5px' }}> Email: support@viralwork.vn</p>
              <p style={{ marginBottom: '15px' }}>
                Hotline: 0903 25 09 88 <br />
              </p>
            </div>
            <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
              <a href="#">
                <img
                  src="https://i.imgur.com/Y0glVyR.png"
                  alt=""
                  style={{
                    height: '24px',
                  }}
                />
              </a>
              <a href="#">
                <img
                  src="https://i.imgur.com/FOodqEe.png"
                  alt=""
                  style={{
                    height: '24px',
                  }}
                />
              </a>
              <a href="#">
                <img
                  src="https://i.imgur.com/Mxr5zPA.png"
                  alt=""
                  style={{
                    height: '24px',
                  }}
                />
              </a>
              <a href="#">
                <img
                  src="https://i.imgur.com/uYjcM9i.png"
                  alt=""
                  style={{
                    height: '24px',
                  }}
                />
              </a>
            </div>
          </div>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 200, paddingTop: '64px' }}>
          <Content style={{ paddingBottom: '50px', height: 'calc(100vh - 64px)', overflow: 'auto' }}>
            <div className="site-layout-content">
              <StyledContainer>{children}</StyledContainer>
            </div>
          </Content>
          {/* <Footer /> */}
        </Layout>
      </Layout>
    </Layout>
  )
}

export default LayoutAuth
