import React, { useState, useEffect } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Row, Typography, Col, Form, Input, Card, Upload, Button, Divider, Space, Select, message } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useAppDispatch } from 'state'
import { createBrand, updateBrand, getBrandDetail } from 'state/brand'
import get from 'lodash/get'
import { useSelector } from 'react-redux'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const AddEditBrand = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [form] = Form.useForm()
  const brand = useSelector((state) => get(state, 'brand'))
  const brandDetailData = brand?.detail || null
  let { brandId }: any = useParams()

  useEffect(() => {
    if (brandId) {
      dispatch(getBrandDetail({ id: brandId }))
    }
  }, [brandId])

  useEffect(() => {
    if (brandDetailData) {
      form.setFieldsValue(brandDetailData)
    }
  }, [brandDetailData])

  const [loading, setLoading] = useState(false)

  const handleSubmitForm = (values: any) => {
    setLoading(true)
    if (brandId) {
      dispatch(updateBrand({ ...values, id: brandId }))
        .then((response: any) => {
          if (response) {
            setLoading(false)
            message.success('Cập nhật thành công!')
            history.push('/brands')
          }
        })
        .catch((errorMessage) => {
          message.error('Error, please try again.')
        })
    } else {
      dispatch(createBrand(values))
        .then((response: any) => {
          if (response) {
            setLoading(false)
            message.success('Thêm thương hiệu thành công!')
            history.push('/brands')
          }
        })
        .catch((errorMessage) => {
          message.error('Error, please try again.')
        })
    }
  }

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Thương hiệu
          </Typography.Title>
          <div>
            <Link to="/brands">
              <Button type="primary" ghost icon={<ArrowLeftOutlined rev={undefined} />}>
                Quay lại
              </Button>
            </Link>
          </div>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card>
            <Form
              form={form}
              name="addBrand"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 11 }}
              onFinish={handleSubmitForm}
              autoComplete="off"
            >
              <Row>
                <Col span={6}>
                  <Typography.Title level={4}>Thông tin</Typography.Title>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label="Tên công ty"
                    name="name"
                    rules={[{ required: true, message: 'Please input Name!' }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Email công ty"
                    name="email"
                    rules={[{ required: true, message: 'Please input Email!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Số đăng ký" name="taxCode">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Lĩnh vực" name="field">
                    <Select>
                      <Select.Option value="fashion">Fashion</Select.Option>
                      <Select.Option value="beauty">Beauty</Select.Option>
                      <Select.Option value="travel">Travel</Select.Option>
                      <Select.Option value="living">Living</Select.Option>
                      <Select.Option value="baby">Baby</Select.Option>
                      <Select.Option value="tech">Tech</Select.Option>
                      <Select.Option value="food">Food</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item {...{ wrapperCol: { span: 14, offset: 5 } }}>
                    <Space>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        size="large"
                        style={{ minWidth: '100px' }}
                      >
                        Lưu
                      </Button>
                      <Link to="/brands">
                        <Button htmlType="button" size="large" style={{ minWidth: '100px' }}>
                          Huỷ
                        </Button>
                      </Link>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>

              {/* <Divider />
              <br />
              <Row>
                <Col span={6}>
                  <Typography.Title level={4}>Thông tin liên hệ</Typography.Title>
                </Col>
                <Col span={18}>
                  <Form.Item
                    label="Người liên hệ"
                    name="name"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email Người liên hệ"
                    name="name"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Số điện Người liên hệ"
                    name="name"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row> */}
              {/* <Divider />
              <br />
              <div style={{ textAlign: 'center', paddingLeft: '105px' }}>
                <Space>
                  <Link to="/brands">
                    <Button htmlType="button" size="large" style={{ minWidth: '100px' }}>
                      Huỷ
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit" size="large" style={{ minWidth: '100px' }}>
                    Lưu
                  </Button>
                </Space>
              </div> */}
            </Form>
          </Card>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default AddEditBrand
