import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CookieService, ECookieVariable } from 'services'
import RequestService from 'services/request'
import { Api } from 'config/api'

export enum ActionType {
  Authenticate = 'AUTHENTICATE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

export enum StatusType {
  Idle = 'IDLE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Error = 'ERROR',
}

export interface AuthState {
  status: StatusType
  error: string | null
  user: null
  isAuth: boolean
  action: ActionType | null
  accessToken: null
}

export const initialState: AuthState = {
  isAuth: false,
  status: StatusType.Pending,
  error: null,
  user: null,
  action: ActionType.Authenticate,
  accessToken: null,
}

export const login: any = createAsyncThunk(
  'auth/login',
  async (payload: { userNameOrEmailAddress: string; password: string; accountType: string }, { rejectWithValue }) => {
    try {
      const res = await new RequestService().post(Api.AUTH_LOGIN, payload)
      if (res && res.error) throw res
      await new CookieService().set(ECookieVariable.USER_ACCESS_TOKEN, res.result.accessToken)
      return {
        isAuth: true,
        userId: res.result.userId,
        error: null,
        accessToken: res.result.accessToken,
      }
    } catch (error) {
      return rejectWithValue({
        isAuth: false,
        user: null,
        error: 'Username or password not correct! Please try again',
      })
    }
  }
)

export const register: any = createAsyncThunk(
  'auth/register',
  async (
    payload: {
      email: string
      password: string
      mobilePhone: string
      name: string
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await new RequestService().post(Api.AUTH_REGISTER, payload)
      if (res.error) throw res
      return {
        user: res,
        error: null,
      }
    } catch (error: any) {
      return rejectWithValue({
        ...error.response.data,
      })
    }
  }
)

export const logout: any = createAsyncThunk('auth/logout', async () => {
  const res = await new RequestService().get(Api.AUTH_LOGOUT)
  if (res.code || res.message) throw res
  return {
    isAuth: false,
    profile: null,
    error: null,
    accessToken: null,
  }
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeUserToken(state) {
      state.accessToken = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      return {
        ...state,
        status: StatusType.Pending,
        action: ActionType.Login,
      }
    })
    builder.addCase(login.fulfilled, (state: any, action) => {
      return {
        ...state,
        action: ActionType.Login,
        status: StatusType.Success,
        userId: action.payload.userId,
        isAuth: action.payload.isAuth,
        accessToken: action.payload.accessToken,
        error: action.payload.error,
      }
    })
    builder.addCase(login.rejected, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        status: StatusType.Error,
        action: ActionType.Login,
        accessToken: null,
        error: action.payload.error,
        user: null,
        isAuth: action.payload.isAuth,
      }
    })
    builder.addCase(logout.fulfilled, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        action: ActionType.Logout,
        status: StatusType.Success,
        isAuth: false,
        accessToken: null,
      }
    })
    builder.addCase(logout.rejected, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        action: ActionType.Logout,
        status: StatusType.Success,
        isAuth: false,
        accessToken: null,
      }
    })
  },
})

export const authSelector = (state: any) => state.auth
const authReducer = authSlice.reducer
export default authReducer
