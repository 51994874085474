import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Form, Input, Select, Upload, Button, Radio } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const { Option } = Select

const InfluencerInfo = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Typography.Title level={4}>Influencer</Typography.Title>
        </Col>
        <Col span={20}>
          <div>
            <Form.Item
              label="Số influencer cần tuyển"
              name="limit"
              rules={[{ required: true, message: 'Nhập Số influencer cần tuyển!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Media" name="media" rules={[{ required: true, message: 'Chọn Media!' }]}>
              <Radio.Group>
                <Radio value="facebook">Facebook</Radio>
                <Radio value="tiktok">Tiktok</Radio>
                <Radio value="youtube">Youtube</Radio>
                <Radio value="instagram">Instagram</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default InfluencerInfo
