
import Axios, { AxiosError } from 'axios';
// import { profileClear } from 'state/profile';
// import store from 'state';
import CookieService, { ECookieVariable } from 'services/cookie';
import ObjectUtil from 'utils/object';

export interface IAlertPayload {
  type?: 'success' | 'warning' | 'danger';
  title?: string;
  message: string;
}


export class RequestError extends Error {
  status: number;

  message: string;

  errors: any;

  error: any;

  alert: IAlertPayload;

  constructor(error: AxiosError) {
    super(error as any);
    this.message = ObjectUtil.getIn(
      error,
      'message',
      'Unknown error from the system',
      (message: string) => message,
    );
    this.errors = ObjectUtil.getIn(error, 'message');
    this.status = ObjectUtil.getIn(error, 'code', 3001);

    // Handle axios error
    if (error.code === 'ECONNABORTED' || error.message === 'Network Error')
      this.message = 'Network Error';
    else if (error && typeof error === 'string') this.message = error;

    this.error = {
      message: this.message,
      errors: this.errors,
      status: this.status,
    };

    this.alert = {
      message: this.message,
      type: 'danger',
    };
  }
}

class RequestService {
  store: any;

  // eslint-disable-next-line no-useless-constructor
  constructor() {
    // this.store = store;
  }

  getConfigs(params = {}) {
    const token = new CookieService().get(ECookieVariable.USER_ACCESS_TOKEN);
    return {
      params: Object.assign(ObjectUtil.cleanObj(params), {}),
      timeout: 20000,
      headers: ObjectUtil.cleanObj({
        authorization: `Bearer ${token}`,
      }),
    };
  }

  async get(subURL: string, params = {}) {
    return Axios.get(`${process.env.REACT_APP_API_URL}${subURL}`, this.getConfigs(params))
      .then((res) => {
        const { code, message } = res.data;
        if (code || message) throw res.data;

        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async post(subURL: string, payload = {}) {
    return Axios.post(`${process.env.REACT_APP_API_URL}${subURL}`, payload, this.getConfigs())
      .then((res) => {
        const { code, message } = res.data;
        if (code || message) throw res.data;

        return res.data;
      })
      .catch((err) => {
        // console.log('this.store');
        if (err?.response?.data?.code === 401) {
          // this.store.dispatch(logout());
          // this.store.dispatch(profileClear());
        }
        throw err;
      });
  }

  async put(subURL: string, payload = {}) {
    return Axios.put(`${process.env.REACT_APP_API_URL}${subURL}`, payload, this.getConfigs())
      .then((res) => {
        const { code, message } = res.data;
        if (code || message) throw res.data;
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  async delete(subURL: string) {
    return Axios.delete(`${process.env.REACT_APP_API_URL}${subURL}`, this.getConfigs())
      .then((res) => {
        const { code, message } = res.data;
        if (code || message) throw res.data;
        return res.data;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export default RequestService;
