import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Tabs, Space, Card, Table, Tag, Image, Button } from 'antd'
import { Youtube, Facebook, Instagram, TickSquare } from 'iconsax-react'
import Applicants from './Applicants'
import ContentPost from './ContentPost'
import Point from './Point'
import Report from './Report'
import { useAppDispatch } from 'state'
import { data, influencers } from './data'
import { getOffers } from 'state/offer'
import { getCampaignDetail } from 'state/campaign'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }
  .ant-card-bordered {
    border-top: none;
    border-radius: 0 4px 4px 4px;
  }
`

const CampaignManagement = () => {
  const dispatch = useAppDispatch()
  let { campaignId }: any = useParams()
  const campaignDetail = useSelector((state) => get(state, 'campaign.detail'))

  useEffect(() => {
    dispatch(getCampaignDetail({ campaignId }))
    dispatch(getOffers({ campaignId }))
  }, [campaignId])

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <div>
              <Image
                style={{ borderRadius: '10px', overflow: 'hidden', objectFit: 'cover' }}
                width={80}
                height={80}
                src={`https://api.agiatot.vn/images/${campaignDetail.mainImage}`}
              />
            </div>
            <div>
              <h4
                style={{
                  fontWeight: 500,
                  fontSize: '1rem',
                  maxWidth: '500px',
                  marginBottom: '10px',
                  // textOverflow: 'ellipsis',
                  // overflow: 'hidden',
                  // whiteSpace: 'nowrap',
                }}
              >
                {campaignDetail?.name}
              </h4>
              <div>
                {!!data.media_tiktok && <Instagram size="20" variant="Outline" />}
                {!!data.media_instagram && <Instagram size="20" variant="Outline" />}
                {!!data.media_facebook && <Facebook size="20" variant="Outline" />}
                {!!data.media_youtube && <Youtube size="20" variant="Outline" />}
              </div>
            </div>
          </div>
          <div>
            <Space>
              <Button type="primary" danger>
                Stop
              </Button>
              <Link to={`/campaigns/4/edit`}>
                <Button type="primary" ghost>
                  Edit
                </Button>
              </Link>
            </Space>
          </div>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <StyledTabs
            type="card"
            defaultActiveKey="1"
            style={{ marginBottom: '0' }}
            items={[
              {
                label: 'Applicants',
                key: '1',
                children: (
                  <Card>
                    <Applicants />
                  </Card>
                ),
              },
              {
                label: 'Content',
                key: '2',
                children: (
                  <Card>
                    <ContentPost />
                  </Card>
                ),
              },
              {
                label: 'Report',
                key: '4',
                children: (
                  <Card>
                    <Report />
                  </Card>
                ),
              },
              {
                label: 'Point',
                key: '5',
                children: (
                  <Card>
                    <Point />
                  </Card>
                ),
              },
            ]}
          />
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default CampaignManagement
