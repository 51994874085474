import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Form, Input, Select, Upload, Button, Typography } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useAppDispatch } from 'state'
const { Option } = Select

const normFile = (e: any) => {
  console.log('Upload event:', e)
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const CampaignInfo = ({ propsUpload }: any) => {
  // const dispatch = useAppDispatch()
  const brandCombobox = useSelector((state) => get(state, 'brand.combobox'))
  const categoryCombobox = useSelector((state) => get(state, 'category.combobox'))
  // const handleFileChange = (file) => {
  //   console.log(file)
  // }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Typography.Title level={4}>Thông tin</Typography.Title>
        </Col>
        <Col span={20}>
          <div>
            <Form.Item
              label="Tên chiến dịch"
              name="name"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Ảnh đại diện">
              {/* <Upload onChange={handleFileChange} listType="picture">
                <Button icon={<UploadOutlined rev={undefined} />}>Click to upload</Button>
              </Upload> */}
              <Upload {...propsUpload}>
                <Button icon={<UploadOutlined rev={undefined} />}>Select File</Button>
              </Upload>
            </Form.Item>
            <div>
              {/* <Button type="primary" onClick={handleUpload}>
                Upload
              </Button> */}
            </div>
            <Form.Item name="brandId" label="Thương hiệu" rules={[{ required: true, message: 'Please select brand!' }]}>
              <Select placeholder="Please select a brand">
                {brandCombobox &&
                  brandCombobox.length > 0 &&
                  brandCombobox.map((item) => <Option value={`${item.value}`}>{item.displayText}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="categoryId"
              label="Danh mục"
              rules={[{ required: true, message: 'Please select category!' }]}
            >
              <Select placeholder="Please select a category">
                {categoryCombobox &&
                  categoryCombobox.length > 0 &&
                  categoryCombobox.map((item) => <Option value={`${item.value}`}>{item.displayText}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              label="Điều khoản & điều kiện"
              name="term"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input.TextArea showCount maxLength={100} style={{ height: 150 }} placeholder="Nhập điều khoản" />
            </Form.Item>
            <Form.Item
              label="Yêu cầu nội dung"
              name="mission"
              rules={[{ required: true, message: 'Please input your username!' }]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 150 }}
                placeholder="Nhập yêu cầu nội dung (vd phong cách review, hình ảnh ...)"
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CampaignInfo
