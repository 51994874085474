import { Request } from 'helpers/request'
import { Api } from 'config/api'

const serverBaseUrl = process.env.REACT_APP_API_URL

export const addOfferApi = (payload: any) => {
    return Request.call({
        url: Api.ADD_UPDATE_OFFER,
        serverBaseUrl: serverBaseUrl,
        method: 'POST',
        data: {
            ...payload,
        },
    })
}

export const getOffersApi = (filter: any) => {
    return Request.call({
        url: `${Api.GET_OFFER_ALL}?campaignId=${filter?.campaignId || ''}`,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}

export const getOfferDetailApi = ({ offerId }: any) => {
    return Request.call({
        url: `${Api.GET_OFFER_DETAIL}?id=${offerId}`,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}
