import React, { useState } from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    background-image: url('/images/bg-login.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    min-height: 100vh;
    width: 100vw;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default function LayoutLogin(props: any) {
  // eslint-disable-next-line react/destructuring-assignment
  return <StyledContainer>{props.children}</StyledContainer>
}
