import { createAsyncThunk } from '@reduxjs/toolkit'

export const createCallApiAsyncAction = <T, R>(
  actionName: string,
  asyncAction: (params: T) => Promise<R>,
) => {
  return createAsyncThunk(actionName, async (params: T, thunkAPI) => {
    try {
      const response = await asyncAction(params)

      // @ts-ignore
      if (response.apiStatus) {
        return {
          response,
          request: params,
        }
      } else {
        throw response
      }
    } catch (e: any) {
      if (e?.errorStatus === 401) {
        const authReducer = require('state/auth')
        thunkAPI.dispatch(authReducer.logout())
      }

      return {
        response: e as R,
        request: params,
      }
    }
    // do after api calling finish
    // eg: thunkAPI.dispatch(actionLoading(false));
  })
}
