import { Request } from 'helpers/request'
import { Api } from 'config/api'

const serverBaseUrl = process.env.REACT_APP_API_URL

export const addCampaignApi = (payload: any) => {
    return Request.call({
        url: Api.ADD_UPDATE_CAMPAIGN,
        serverBaseUrl: serverBaseUrl,
        method: 'POST',
        data: {
            ...payload,
        },
    })
}

export const getCampaignsApi = () => {
    return Request.call({
        url: Api.GET_CAMPAIGN_ALL,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}

export const getCampaignDetailApi = ({ campaignId }: any) => {
    return Request.call({
        url: `${Api.GET_CAMPAIGN_DETAIL}?id=${campaignId}`,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}

export const getCampaignContentPostApi = ({ campaignId }: any) => {
    return Request.call({
        url: `${Api.GET_CAMPAIGN_CONTENT_POST}?campaignId=${campaignId}`,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}
