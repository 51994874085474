import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Avatar, Select, Modal, Form, Input, Image, Button, Space, Divider, message } from 'antd'
import { AntDesignOutlined } from '@ant-design/icons'
import { useAppDispatch } from 'state'
import { getCampaigns } from 'state/campaign'
import { getInfluencers } from 'state/influencer'
import { useSelector } from 'react-redux'
import { addOffer } from 'state/offer'
import { StyledFormLabel, StyledFormItem } from './styleds'

const InfluencerOfferModal = (props) => {
  const { isOpen, onClose, influencer }: any = props
  const dispatch = useAppDispatch()
  const campaign = useSelector((state) => get(state, 'campaign.data'))
  //console.log(campaign)
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(getCampaigns({}))
    dispatch(getInfluencers({}))
  }, [])

  const handleCloseModal = () => {
    onClose()
    form.resetFields()
  }

  const handleSubmitForm = (values: any) => {
    var campaignSelected = campaign.items.find((item) => item.id == values.campaignId)
    const data = {
      ...values,
      influencerId: influencer.id,
      campaignId: campaignSelected.id,
    }

    dispatch(addOffer(data)).then((response: any) => {
      if (response) {
        setLoading(false)
        onClose()
        form.resetFields()
        message.success('Cập nhật thành công!')
      }
    })
  }

  return (
    <Modal title="Offer Campaign" open={isOpen} onCancel={handleCloseModal} footer={null}>
      <div>
        <Divider />
        <Form
          onFinish={handleSubmitForm}
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          initialValues={{ message: 'You just got a campaign offer.' }}
        >
          <StyledFormItem>
            <StyledFormLabel>Influencer</StyledFormLabel>
            <div style={{ marginTop: '5px' }}>
              <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <Image
                  preview={false}
                  style={{ borderRadius: '50%', overflow: 'hidden' }}
                  width={50}
                  src="/images/influencers/i1.jpg"
                />
                <div>
                  <h3>Thảo_Phạm_181</h3>
                  <div style={{ color: 'rgb(118, 118, 118)', fontSize: '0.825' }}>Nữ / 26/ Ha Noi (city)</div>
                </div>
              </div>
            </div>
          </StyledFormItem>

          <Form.Item name="campaignId" label="Offer Campaign" rules={[{ required: true }]}>
            <Select style={{ width: '100%' }}>
              {campaign &&
                campaign.items.length > 0 &&
                campaign.items.map((item) => (
                  <Select.Option key={item.id}>
                    <Space>
                      {/* <div>
                        <Image preview={false} width={50} src="/images/campaign/c2.jpg" />
                      </div> */}
                      <div>
                        <div style={{ fontSize: '1.25', fontWeight: 'bold' }}>{item.name}</div>
                        <div style={{ fontSize: '0.25', color: '#a8a8a8' }}>
                          <Space>
                            <Image preview={false} width={16} src="/images/icon-instagram.svg" />
                            <span> {item.applyEndDate}</span>
                          </Space>
                        </div>
                      </div>
                    </Space>
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
            <Input addonAfter="POINT" />
          </Form.Item>
          <Form.Item name="message" label="Offer Message" rules={[{ required: true }]}>
            <Input.TextArea rows={4} />
          </Form.Item>
          <Button type="primary" loading={loading} size="large" htmlType="submit" style={{ width: '100%' }}>
            Send Offer
          </Button>
        </Form>
      </div>
    </Modal>
  )
}

export default InfluencerOfferModal
