import React from 'react'
import Container from 'components/Container'
import Layout from 'components/Layout'

const Dashboard = () => {
  return (
    <Layout>
      <Container>
        <h1>Dashboard</h1>
      </Container>
    </Layout>
  )
}

export default Dashboard
