import { Request } from 'helpers/request'
import { Api } from 'config/api'

const serverBaseUrl = process.env.REACT_APP_API_URL

// export const login = (payload: any) => {
//   return Request.call({
//     url: '/identity.login',
//     serverBaseUrl: serverBaseUrl,
//     method: 'POST',
//     data: {
//       ...payload,
//     },
//   })
// }

export const getProfileApi = () => {
    return Request.call({
        url: Api.GET_PROFILE,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}

export const updateProfileApi = (payload: any) => {
    return Request.call({
        url: Api.GET_PROFILE,
        serverBaseUrl: serverBaseUrl,
        method: 'PUT',
        data: {
            ...payload,
        }
    })
}
