import { Request } from 'helpers/request'
import { Api } from 'config/api'

const serverBaseUrl = process.env.REACT_APP_API_URL


export const addInfluencerApi = (payload: any) => {
    return Request.call({
        url: Api.ADD_UPDATE_INFLUENCER,
        serverBaseUrl: serverBaseUrl,
        method: 'POST',
        data: {
            ...payload,
        },
    })
}

export const updateInfluencerApi = (payload: any) => {
    return Request.call({
        url: Api.ADD_UPDATE_INFLUENCER,
        serverBaseUrl: serverBaseUrl,
        method: 'POST',
        data: {
            ...payload,
        },
    })
}

export const getInfluencersApi = () => {
    return Request.call({
        url: Api.GET_INFLUENCER_ALL,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}

export const getInfluencerDetailApi = ({ influencerId }: any) => {
    return Request.call({
        url: `${Api.GET_INFLUENCER_DETAIL}?id=${influencerId}`,
        serverBaseUrl: serverBaseUrl,
        method: 'GET',
    })
}
