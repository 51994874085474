import styled from 'styled-components'
import { Button } from 'antd'

export const StyledHeaderCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 30px 0;
  gap: 30px;
`
export const StyledBoxStat = styled.div`
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #dbdbdb;
  padding: 15px 20px;
  width: 100%;
  gap: 30px;
  cursor: pointer;
  &.active {
    background: #5c19dd;
    *,
    .title {
      color: #fff;
    }
  }
  .title {
    color: #767676;
    font-size: 16px;
    font-weight: 400;
    padding-top: 1px;
    line-height: 24px !important;
    margin-bottom: 15px;
  }
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .number {
      font-size: 25px;
      font-weight: 500;
      line-height: 1;
    }
  }
`
