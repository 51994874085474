import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import RequestService from 'services/request'
import { Api } from 'config/api'
import get from 'lodash/get'

import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { getBrandComboBoxApi } from 'api/brand'

export const initialState: any = {
  isLoading: false,
  data: null,
  detail: null,
  combobox: null,
  error: false,
}

export const getBrandComboBox: any = createCallApiAsyncAction('brand/getListCombobox', getBrandComboBoxApi)

export const getBrandList: any = createAsyncThunk('brand/getList', async (payload: any, { rejectWithValue }) => {
  try {
    const res = await new RequestService().get(`${Api.GET_LIST_BRAND}`)
    if (res.error) throw res
    return {
      error: false,
      data: get(res, 'result', null),
    }
  } catch (error) {
    return rejectWithValue({
      data: null,
      error: true,
    })
  }
})

export const getBrandDetail: any = createAsyncThunk('brand/getDetail', async (payload: any, { rejectWithValue }) => {
  try {
    const res = await new RequestService().get(`${Api.GET_DETAIL_BRAND}?id=${payload.id}`)
    if (res.error) throw res
    return {
      error: false,
      data: get(res, 'result.brand', null),
    }
  } catch (error) {
    return rejectWithValue({
      data: null,
      error: true,
    })
  }
})

export const createBrand: any = createAsyncThunk('brand/create', async (payload: any, { rejectWithValue }) => {
  try {
    const res = await new RequestService().post(Api.CREATE_BRAND, payload)
    if (res && res.error) throw res

    return {
      profile: get(res, 'result.brand', null),
      error: null,
    }
  } catch (error) {
    return rejectWithValue({
      user: null,
      error: 'Please try again',
    })
  }
})

export const updateBrand: any = createAsyncThunk('brand/update', async (payload: any, { rejectWithValue }) => {
  try {
    const res = await new RequestService().put(Api.UPDATE_BRAND, payload)
    if (res && res.error) throw res

    return {
      data: get(res, 'result.brand', null),
      error: null,
    }
  } catch (error) {
    return rejectWithValue({
      data: null,
      error: 'Please try again',
    })
  }
})

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBrandList.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getBrandList.fulfilled, (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        data: data,
      }
    })
    builder.addCase(getBrandList.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        data: null,
      }
    })
    builder.addCase(getBrandDetail.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getBrandDetail.fulfilled, (state, action) => {
      const { data } = action.payload
      return {
        ...state,
        isLoading: false,
        detail: data,
      }
    })
    builder.addCase(getBrandDetail.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        detail: null,
      }
    })
    builder.addCase(getBrandComboBox.fulfilled, (state, action) => {
      const { response }: any = action.payload
      return {
        ...state,
        isLoading: false,
        combobox: response.result || [],
      }
    })
    builder.addCase(getBrandComboBox.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        combobox: null,
      }
    })
  },
})

export const brandSelector = (state: any) => state.brand

const brandReducer = brandSlice.reducer

export default brandReducer

// Actions
// export const { profileClear } = brandSlice.actions
