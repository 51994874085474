import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import RequestService from 'services/request'
import { Api } from 'config/api'
import get from 'lodash/get'

import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { addOfferApi, getOffersApi, getOfferDetailApi } from 'api/offer'

export const initialState: any = {
  isLoading: false,
  data: null,
  detail: null,
  combobox: null,
  error: false,
}

export const addOffer: any = createCallApiAsyncAction('offer/add', addOfferApi)
export const getOffers: any = createCallApiAsyncAction('offer/getAll', getOffersApi)
export const getOfferDetail: any = createCallApiAsyncAction('offer/getDetail', getOfferDetailApi)

export const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOffers.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getOffers.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        data: get(response, 'result', null),
      }
    })
    builder.addCase(getOffers.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        data: null,
      }
    })
    builder.addCase(getOfferDetail.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getOfferDetail.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        detail: get(response, 'result.offer', null),
      }
    })
    builder.addCase(getOfferDetail.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        detail: null,
      }
    })
  },
})

export const offerSelector = (state: any) => state.offer

const offerReducer = offerSlice.reducer

export default offerReducer
