import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Form, Input, Select, Upload, Button, Radio, DatePicker } from 'antd'

const dateFormat = 'DD-MM-YYYY'

const Schedule = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Typography.Title level={4}>Thời gian</Typography.Title>
        </Col>
        <Col span={20}>
          <div>
            <Form.Item style={{ marginBottom: 0 }} label="Ngày ứng tuyển">
              <Form.Item name="applyStartDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
              <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}>-</span>
              <Form.Item name="applyEndDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="Ngày chọn lọc">
              <Form.Item name="selectStartDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
              <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}>-</span>
              <Form.Item name="selectEndDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="Ngày đăng bài">
              <Form.Item name="postStartDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
              <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}>-</span>
              <Form.Item name="postEndDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }} label="Ngày đánh giá">
              <Form.Item name="evaluationStartDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
              <span style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}>-</span>
              <Form.Item name="evaluationEndDate" style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}>
                <DatePicker style={{ width: '100%' }} format={dateFormat} />
              </Form.Item>
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Schedule
