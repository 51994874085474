
const themeConfig = {
  token: {
    colorPrimary: "#7645D9",
    borderRadius: 4,

  },
};

export default themeConfig;
