import styled from 'styled-components'

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ColSelect = styled.div`
  flex: 0 0 50px;
`

export const ColProfile = styled.div`
  flex: 0 0 350px;
  padding-right: 20px;
  .profileWrapper {
    display: flex;
    align-items: center;
  }
  .profileImage {
    flex: 0 0 120px;
  }
`

export const ColStat = styled.div`
  padding-right: 30px;
  width: 100%;
`

export const ColAction = styled.div`
  flex: 0 0 130px;
`

export const ColStatWrapper = styled.div`
  display: flex;
`

export const ColStatItem = styled.div`
  flex: 0 0 25%;
`

export const ColStatNumber = styled.div`
  font-weight: 700;
  font-size: 1rem;
`

export const ColStatLabel = styled.div`
  color: #767676;
  font-size: 0.825rem;
`

export const ColProfileCategory = styled.div`
  color: #767676;
  font-size: 0.825rem;
  margin-top: 7px;
  .interestItem {
    margin-right: 5px;
    &::after {
      content: '';
      width: 1px;
      display: inline-block;
      background-color: #dfdfdf;
      height: 10px;
      margin-left: 7px;
      vertical-align: -1px;
    }
    &:last-child::after {
      display: none;
    }
  }
`

export const ColProfileName = styled.h3`
  line-height: 18px;
  font-weight: 700;
  color: #222;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 146px;
  display: block;
  font-size: 1rem;
`
export const StyledSearchWrapper = styled.h3`
  display: flex;
  font-size: 2rem;
  position: relative;
  width: 100%;
  height: 77px;
  padding: 5px;
  background-image: linear-gradient(94deg, #ff3d7a, #7526ff 55%);
  border-radius: 12px;
  input {
    width: 100%;
    padding: 0 20px;
    font-size: 1.25rem;
    background: #ebf6ff;
    border: none;
    border-radius: 8px;
    outline: none !important;
  }
`
