import BaseAxios, { AxiosInstance } from 'axios'
import get from 'lodash/get'
import CookieService, { ECookieVariable } from 'services/cookie';

const HIDE_MESSAGE_ERROR_CODES = [404, 500, 9999]


class RequestClass {
  axios: AxiosInstance
  constructor() {
    this.axios = BaseAxios.create({ timeout: 60000 })
  }
  async call<ResponseObj>(config: {
    url: string
    method: string
    data?: any
    serverBaseUrl?: string
    headers?: any
  }) {
    try {
      const serverBaseUrl = process.env.REACT_APP_BASE_API
      // const state = store.getState()
      // const token = get(state, 'auth.accessToken')
      const token = new CookieService().get(ECookieVariable.USER_ACCESS_TOKEN);

      if (!config.headers) {
        config = {
          ...config,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      }
      if (token) {
        config = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          },
        }
        if (config?.method === 'GET') {
          config.data = undefined
        }
      }

      config.data = {
        ...config.data,
      }

      const res = await this.axios.request({
        baseURL: config?.serverBaseUrl || serverBaseUrl,
        ...config,
      })

      return { ...res.data, apiStatus: 1 } as any
    } catch (error) {
      const errorStatus = get(error, 'response.status', null)
      const data = get(error, 'response.data', {}) || {}

      if (
        HIDE_MESSAGE_ERROR_CODES.includes(errorStatus) ||
        HIDE_MESSAGE_ERROR_CODES.includes(data?.code) ||
        !data.message
      ) {
        data.message = 'Oops, Something Went Wrong'
      }
      if (get(error, 'message') === 'Network Error') {
        data.message = 'Network Error'
        data.code = 444
      }

      return {
        ...data,
        apiStatus: 0,
        errorStatus,
      } as unknown as any
    }
  }
}

const Request = new RequestClass()

export { Request }
