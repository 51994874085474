export enum Prefix {
  AUTH_ME = 'identity.me',
  AUTH_LOGIN = 'identity.login',
  AUTH_LOGOUT = '',
  AUTH_REGISTER = 'admin.identity.register',
}

export enum Api {
  AUTH_LOGIN = '/TokenAuth/Authenticate',
  AUTH_LOGOUT = '/TokenAuth/LogOut',
  AUTH_REGISTER = '/services/app/Advertiser/Create',
  GET_PROFILE = '/services/app/Advertiser/GetProfile',
  UPDATE_PROFILE = '/services/app/Advertiser/Update',
  ADD_UPDATE_CAMPAIGN = '/services/app/Campaign/CreateOrUpdate',
  GET_CAMPAIGN_ALL = '/services/app/Campaign/GetAll',
  GET_CAMPAIGN_DETAIL = '/services/app/Campaign/Get',
  GET_CAMPAIGN_CONTENT_POST = '/services/app/CampaignPosting/GetCampaignPostings',
  CREATE_BRAND = '/services/app/Brand/Create',
  UPDATE_BRAND = '/services/app/Brand/Update',
  GET_LIST_BRAND = '/services/app/Brand/GetAll',
  GET_DETAIL_BRAND = '/services/app/Brand/Get',
  GET_COMBOBOX_BRAND = '/services/app/Lookup/GetBrandComboboxItem',
  GET_COMBOBOX_CATEGORY = '/services/app/Lookup/GetCategoryComboboxItem',
  ADD_UPDATE_INFLUENCER = '/services/app/Influencer/CreateOrUpdate',
  GET_INFLUENCER_ALL = '/services/app/Influencer/GetAll',
  GET_INFLUENCER_DETAIL = '/services/app/Influencer/Get',
  ADD_UPDATE_OFFER = '/services/app/CampaignOffer/SendOfferToInfluencer',
  GET_OFFER_ALL = '/services/app/CampaignOffer/GetCampaignOffers',
  GET_OFFER_DETAIL = '/services/app/CampaignOffer/Get',
  UPLOAD_IMAGE = '/File/Upload',
}