const data = {
    "total_parent_child": 1299,
    "is_searching": false,
    "total": 1295,
    "per_page": 10,
    "current_page": 1,
    "last_page": 130,
    "next_page_url": "",
    "prev_page_url": null,
    "from": 1,
    "to": 10,
    "data": [
        {
            "id": 2949,
            "id_history": 1299,
            "hash": "64af6f8863152318157664",
            "created_by": 3430753,
            "email": "wyyvnct@gmail.com",
            "country": "vn",
            "market": "global",
            "name": "C\u00d4NG TY TNHH TH\u1ef0C PH\u1ea8M TH\u1ecaT B\u00d2 WANGHENIU",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "wyyvnct@gmail.com",
            "registration_no": "14260",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-13 03:29:12",
            "updated_at": "2023-07-13 03:29:12",
            "connected_at": null,
            "partner_id": "",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Mr. \u00dd",
            "contact_email": "wyyvnct@gmail.com",
            "contact_phone": "0986355551"
        },
        {
            "id": 2943,
            "id_history": 1298,
            "hash": "64ae2a475d35d653685041",
            "created_by": 3378693,
            "email": "minhngo100398@gmail.com",
            "country": "vn",
            "market": "global",
            "name": "Faraday",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "minhngo100398@gmail.com",
            "registration_no": "2940",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-12 04:21:27",
            "updated_at": "2023-07-12 04:21:27",
            "connected_at": null,
            "partner_id": "revu_vn",
            "partner_uid": 3378693,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Ms.Jenny",
            "contact_email": "minhngo100398@gmail.com",
            "contact_phone": "0973195313"
        },
        {
            "id": 2939,
            "id_history": 1297,
            "hash": "64a7e139cf5a0814910755",
            "created_by": 3432050,
            "email": "info@bachkhang.net",
            "country": "vn",
            "market": "global",
            "name": "SHAKENOW",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "info@bachkhang.net",
            "registration_no": "2936",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-07 09:56:09",
            "updated_at": "2023-07-07 09:56:09",
            "connected_at": null,
            "partner_id": "revu_vn",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "\u0110inh Th\u1ecb Thu Trang",
            "contact_email": "info@bachkhang.net",
            "contact_phone": "0986117769"
        },
        {
            "id": 2935,
            "id_history": 1296,
            "hash": "64a66b668e098030604939",
            "created_by": 19829,
            "email": "marketing.tinysun@gmail.com",
            "country": "vn",
            "market": "global",
            "name": "C\u00d4NG TY TNHH TINY SUN",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "marketing.tinysun@gmail.com",
            "registration_no": "2935",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-06 07:21:10",
            "updated_at": "2023-07-06 07:21:10",
            "connected_at": null,
            "partner_id": "revu_vn",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Mr.Ngh\u0129a",
            "contact_email": "marketing.tinysun@gmail.com",
            "contact_phone": "836910886"
        },
        {
            "id": 2934,
            "id_history": 1295,
            "hash": "64a665e033480566342980",
            "created_by": 3430753,
            "email": "marketing@mylearth.com.sg",
            "country": "vn",
            "market": "global",
            "name": "C\u00d4NG TY C\u1ed4 PH\u1ea6N LEARTH VI\u1ec6T NAM LEARTH VIET NAM JOINT STOCK COMPANY",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "marketing@mylearth.com.sg",
            "registration_no": "14205",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-06 06:57:36",
            "updated_at": "2023-07-06 06:57:36",
            "connected_at": null,
            "partner_id": "",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "TEO SENG LEE",
            "contact_email": "marketing@mylearth.com.sg",
            "contact_phone": "0313658446"
        },
        {
            "id": 2931,
            "id_history": 1294,
            "hash": "64a51775df961580456405",
            "created_by": 3366841,
            "email": "marketing.sybazzarvn@gmail.com",
            "country": "vn",
            "market": "global",
            "name": "C\u00d4NG TY TNHH TH\u01af\u01a0NG M\u1ea0I XU\u1ea4T NH\u1eacP KH\u1ea8U & X\u00c2Y D\u1ef0NG KNC VI\u1ec6T NAM",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "marketing.sybazzarvn@gmail.com",
            "registration_no": "2923",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-05 07:10:45",
            "updated_at": "2023-07-05 07:10:45",
            "connected_at": null,
            "partner_id": "revu_vn",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Nguy\u1ec5n V\u0103n C\u01b0\u1eddng",
            "contact_email": "marketing.sybazzarvn@gmail.com",
            "contact_phone": "0931024628"
        },
        {
            "id": 2922,
            "id_history": 1293,
            "hash": "64a254d22ab20608616320",
            "created_by": 3430753,
            "email": "huong@justdep.vn",
            "country": "vn",
            "market": "global",
            "name": "SIB - Brand Breeze Men",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "huong@justdep.vn",
            "registration_no": "14180",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-07-03 04:55:46",
            "updated_at": "2023-07-04 02:26:24",
            "connected_at": "2023-07-04 02:26:24",
            "partner_id": "",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Ms. H\u01b0\u1edbng",
            "contact_email": "huong@justdep.vn",
            "contact_phone": "0868057509"
        },
        {
            "id": 2919,
            "id_history": 1292,
            "hash": "649e338d56b76713314437",
            "created_by": 3430753,
            "email": "ngan.truong@vn.revu.net",
            "country": "vn",
            "market": "global",
            "name": "Tera Group",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "ngan.truong@vn.revu.net",
            "registration_no": "14172",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-06-30 01:44:45",
            "updated_at": "2023-06-30 01:44:45",
            "connected_at": null,
            "partner_id": "",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Ng\u00e2n",
            "contact_email": "ngan.truong@vn.revu.net",
            "contact_phone": "0353220090"
        },
        {
            "id": 2918,
            "id_history": 1291,
            "hash": "649cf091b4e0d881669975",
            "created_by": 3366841,
            "email": "madeleine.hoanggg@gmail.com",
            "country": "vn",
            "market": "global",
            "name": "C\u00d4NG TY C\u1ed4 PH\u1ea6N METUB VI\u1ec6T NAM",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "madeleine.hoanggg@gmail.com",
            "registration_no": "2914",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-06-29 02:46:41",
            "updated_at": "2023-06-29 02:46:41",
            "connected_at": null,
            "partner_id": "revu_vn",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "H\u00c0 TH\u1eca T\u00da PH\u01af\u1ee2NG",
            "contact_email": "madeleine.hoanggg@gmail.com",
            "contact_phone": "0313658809"
        },
        {
            "id": 2913,
            "id_history": 1290,
            "hash": "649957531ac59749860171",
            "created_by": 3432050,
            "email": "anh.ktt.gdd@gmail.com",
            "country": "vn",
            "market": "global",
            "name": "YINSHI",
            "profile": "",
            "balance": 0,
            "parent_id": 0,
            "child_count": 0,
            "contact_count": 1,
            "memo_count": 0,
            "state": 1,
            "report_email": "anh.ktt.gdd@gmail.com",
            "registration_no": "2913",
            "phone": "",
            "address1": "",
            "address2": "",
            "postcode": "",
            "created_at": "2023-06-26 09:16:03",
            "updated_at": "2023-06-26 09:17:21",
            "connected_at": null,
            "partner_id": "revu_vn",
            "partner_uid": null,
            "advertiser_field_id": 0,
            "representative": "",
            "account_setting": "use",
            "contact_name": "Ms. Lanly",
            "contact_email": "anh.ktt.gdd@gmail.com",
            "contact_phone": "0938608425"
        }
    ]
}

export default data