import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch } from 'react-redux';
import profileReducer, { initialState as profileInitialState } from './profile';
import brandReducer, { initialState as brandInitialState } from './brand';
import campaignReducer, { initialState as campaignInitialState } from './campaign';
import offerReducer, { initialState as offerInitialState } from './offer';
import influencerReducer, { initialState as influencerInitialState } from './influencer';
import categoryReducer, { initialState as categoryInitialState } from './category';
import authReducer, { initialState as authInitialState } from './auth';


import { updateVersion } from './global/actions';

const PERSISTED_KEYS: string[] = [
  'profile',
  'campaign',
  'influencer',
  'brand',
  'category',
  'auth',
  'offer',
];

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    profile: profileReducer,
    auth: authReducer,
    brand: brandReducer,
    category: categoryReducer,
    campaign: campaignReducer,
    influencer: influencerReducer,
    offer: offerReducer,
  },
  middleware: [
    ...getDefaultMiddleware({ thunk: true }),
    save({ states: PERSISTED_KEYS }),
  ],
  preloadedState: load({
    states: PERSISTED_KEYS,
    preloadedState: {
      profile: cloneDeep(profileInitialState),
      auth: cloneDeep(authInitialState),
      brand: cloneDeep(brandInitialState),
      category: cloneDeep(categoryInitialState),
      campaign: cloneDeep(campaignInitialState),
      influencer: cloneDeep(influencerInitialState),
      offer: cloneDeep(offerInitialState),
    },
  }),
});

store.dispatch(updateVersion());

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch();

export default store;


// export const persistor = persistStore(store, undefined, () => {
//   store.dispatch(updateVersion())
// })

// export function useStore(initialState) {
//   return useMemo(() => initializeStore(initialState), [initialState])
// }
