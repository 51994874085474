import React from 'react'
import styled from 'styled-components'
import { Tabs, Space, Card, Table, Tag, Image, Button } from 'antd'
import { data, influencers } from './data'

const Point = () => {
  const columns: any = [
    {
      title: 'Influencer',
      dataIndex: '',
      render: (_, record) => (
        <div>
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <div>
              <Image style={{ borderRadius: '50%', overflow: 'hidden' }} width={60} src={record.profile} />
            </div>
            <div>
              <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>{record.nickname}</h4>
              <div style={{ color: '#a8a8a8', fontSize: '0.825rem' }}>
                <span>{record.gender === 'M' ? 'Nam' : 'Nữ'}</span>
                <span> / {record.age}</span>
                <span>/{record.province}</span>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Point',
      dataIndex: 'Submission',
      key: 'Submission',
      render: (_, record) => <div>10,000P</div>,
    },
    {
      title: 'Description',
      dataIndex: 'instagram_user_follower',
      key: 'instagram_user_follower',
      render: (_, record) => <div>Automatically when the campaign is completed</div>,
    },
    {
      title: 'Date',
      dataIndex: 'instagram_user_follower',
      key: 'instagram_user_follower',
      render: (_, record) => <div>10.01.2023 10:00:01</div>,
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => (
        <Button type="primary" ghost>
          Add Point
        </Button>
      ),
    },
  ]
  return (
    <div>
      <Table columns={columns} dataSource={influencers.data.list} />
    </div>
  )
}

export default Point
