import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch, Redirect, RouteComponentProps, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { LangType } from '@pancakeswap-libs/uikit'
import AddCampaign from './Campaign/AddCampaign'
import CampaignList from './Campaign/CampaignList'
import AddEditAdvertiser from './Advertiser/AddEditAdvertiser'
import AdvertiserList from './Advertiser/AdvertiserList'
import EditProfile from './EditProfile'
import AddEditBrand from './Brand/AddEditBrand'
import BrandList from './Brand/BrandList'
import OfferList from './Offer/OfferList'
import PointExchangeList from './Point/PointExchangeList'
import InfluencerList from './Influencer/InfluencerList'
import CampaignManagement from './Campaign/CampaignManagement'
import Login from './Login'
import Signup from './Signup'
import Dashboard from './Dashboard'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import get from 'lodash/get'
import { useAppDispatch } from 'state'
// import Layout from '../components/Layout'
import { getProfile } from 'state/profile'

// const AppWrapper = styled.div`
//   display: flex;
//   flex-flow: column;
//   align-items: flex-start;
//   overflow-x: hidden;
// `

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  margin-bottom: 64px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`

const CACHE_KEY = 'pancakeSwapLanguage'

export default function App() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const accessToken = useSelector((state) => get(state, 'auth.accessToken'))

  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  const fileId = 6
  // const credentials: Credentials = {
  //   token: apiKey,
  // }

  // const stringTranslationsApi = new StringTranslations(credentials)

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  useEffect(() => {
    const storedLangCode = localStorage.getItem(CACHE_KEY)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  useEffect(() => {
    dispatch(getProfile({}))
  }, [])

  // useEffect(() => {
  //   if (!accessToken) {
  //     window.location.replace('/#/login')
  //   }
  // }, [accessToken])

  const fetchTranslationsForSelectedLanguage = async () => {
    // stringTranslationsApi
    //   .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
    //   .then((translationApiResponse) => {
    //     if (translationApiResponse.data.length < 1) {
    //       setTranslations(['error'])
    //     } else {
    //       setTranslations(translationApiResponse.data)
    //     }
    //   })
    //   .then(() => setTranslatedLanguage(selectedLanguage))
    //   .catch((error) => {
    //     setTranslations(['error'])
    //     console.error(error)
    //   })
  }

  useEffect(() => {
    if (selectedLanguage) {
      fetchTranslationsForSelectedLanguage()
    }
  }, [selectedLanguage])

  const handleLanguageSelect = (langObject: LangType) => {
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
  }

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <div>
          <LanguageContext.Provider
            value={{
              selectedLanguage,
              setSelectedLanguage: handleLanguageSelect,
              translatedLanguage,
              setTranslatedLanguage,
            }}
          >
            {/* <TranslationsContext.Provider value={{ translations, setTranslations }}> */}
            {/* {isLogin ? ( */}
            <>
              <BodyWrapper>
                <Switch>
                  <Route exact strict path="/dashboard" component={Dashboard} />
                  <Route exact strict path="/influencers" component={InfluencerList} />
                  <Route exact strict path="/campaigns" component={CampaignList} />
                  <Route exact strict path="/campaigns/add" component={AddCampaign} />
                  <Route exact strict path="/campaigns/:campaignId/edit" component={AddCampaign} />
                  <Route exact strict path="/campaigns/:campaignId/management" component={CampaignManagement} />
                  <Route exact strict path="/brands" component={BrandList} />
                  <Route exact strict path="/offers" component={OfferList} />
                  <Route exact strict path="/points" component={PointExchangeList} />
                  <Route exact strict path="/brands/add" component={AddEditBrand} />
                  <Route exact strict path="/brands/:brandId/edit" component={AddEditBrand} />
                  <Route exact strict path="/advertisers" component={AdvertiserList} />
                  <Route exact strict path="/advertisers/add" component={AddEditAdvertiser} />
                  <Route exact strict path="/advertisers/:advertiserId/edit" component={AddEditAdvertiser} />
                  <Route exact strict path="/edit-profile" component={EditProfile} />
                  <Route exact strict path="/login" component={Login} />
                  <Route exact strict path="/signup" component={Signup} />
                  <Route
                    component={({ location }: RouteComponentProps) => (
                      <Redirect to={{ ...location, pathname: '/dashboard' }} />
                    )}
                  />
                </Switch>
              </BodyWrapper>
            </>

            {/* </TranslationsContext.Provider> */}
          </LanguageContext.Provider>
        </div>
      </HashRouter>
    </Suspense>
  )
}
