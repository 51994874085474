import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Card, Table, Button, Typography, Space, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import data from './data'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const AdvertiserList = () => {
  const [modal, contextHolder] = Modal.useModal()

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const confirm = () => {
    modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined rev={undefined} />,
      content: 'Bla bla ...',
      okText: '确认',
      cancelText: '取消',
    })
  }

  const columns: any = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Tên',
      dataIndex: 'contact_name',
      key: 'contact_name',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Email',
      dataIndex: 'contact_email',
      key: 'contact_email',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Phone',
      dataIndex: 'contact_phone',
      key: 'contact_phone',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      width: '100px',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Button type="link" danger onClick={confirm}>
            Khoá
          </Button>
          <Link to="/advertisers/1/edit">
            <Button type="link" ghost>
              Sửa
            </Button>
          </Link>
        </Space>
      ),
    },
  ]

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Nhà quảng cáo
          </Typography.Title>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card bordered={false}>
            <Table columns={columns} dataSource={data.data} />
          </Card>
        </Wrapper>
      </Container>
      {/* <Modal
        title="Confirm"
        size="small"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="Đồng ý"
        cancelText="Huỷ"
      >
        <p>Do you want to block this advertiser?</p>
      </Modal> */}
    </Layout>
  )
}

export default AdvertiserList
