import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import RequestService from 'services/request'
import { Api } from 'config/api'
import get from 'lodash/get'

import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { addCampaignApi, getCampaignsApi, getCampaignDetailApi, getCampaignContentPostApi } from 'api/campaign'

export const initialState: any = {
  isLoading: false,
  data: null,
  detail: null,
  contentPost: null,
  combobox: null,
  error: false,
}

export const addCampaign: any = createCallApiAsyncAction('campaign/add', addCampaignApi)
export const getCampaigns: any = createCallApiAsyncAction('campaign/getAll', getCampaignsApi)
export const getCampaignDetail: any = createCallApiAsyncAction('campaign/getDetail', getCampaignDetailApi)
export const getCampaignContentPost: any = createCallApiAsyncAction(
  'campaign/getContentPost',
  getCampaignContentPostApi
)

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCampaigns.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getCampaigns.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        data: get(response, 'result', null),
      }
    })
    builder.addCase(getCampaigns.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        data: null,
      }
    })
    builder.addCase(getCampaignDetail.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getCampaignDetail.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        detail: get(response, 'result.campaign', null),
      }
    })
    builder.addCase(getCampaignDetail.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        detail: null,
      }
    })
    builder.addCase(getCampaignContentPost.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getCampaignContentPost.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        contentPost: get(response, 'result', null),
      }
    })
    builder.addCase(getCampaignContentPost.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        contentPost: null,
      }
    })
  },
})

export const campaignSelector = (state: any) => state.brand

const campaignReducer = campaignSlice.reducer

export default campaignReducer
