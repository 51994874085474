import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Card, Typography, Space, Table, Button, Tag, Image } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import get from 'lodash/get'
import { useAppDispatch } from 'state'
import { getBrandList } from 'state/brand'
import { useSelector } from 'react-redux'
import InfluencerOfferModal from '../InfluencerOfferModal'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const PointExchangeList = () => {
  const dispatch = useAppDispatch()
  const brand = useSelector((state) => get(state, 'brand'))

  const [offerSelected, setOfferSelected] = useState<any>()
  const [isModalOfferOpen, setModalOfferOpen] = useState(false)

  useEffect(() => {
    dispatch(getBrandList())
  }, [])

  const handleOpenOfferCampaign = (offer) => {
    setOfferSelected(offer)
    setModalOfferOpen(true)
  }

  const handleCloseOfferCampaign = () => {
    setOfferSelected(null)
    setModalOfferOpen(false)
  }

  const columns: any = [
    {
      title: 'Influencer',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (row) => (
        <div>
          <Space>
            <Image
              width={40}
              style={{ borderRadius: '50%' }}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
            <div>bae.sajang</div>
          </Space>
        </div>
      ),
    },

    {
      title: 'Request Point',
      dataIndex: 'offerAmount',
      key: 'offerAmount',
      render: (text) => <span>1.000.000P</span>,
    },
    {
      title: 'Fee',
      dataIndex: 'offerAmount',
      key: 'offerAmount',
      render: (text) => <span>1000P</span>,
    },
    {
      title: 'Tax',
      dataIndex: 'offerAmount',
      key: 'offerAmount',
      render: (text) => <span>100P</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isLockout',
      key: 'isLockout',
      render: (isLockout) => {
        if (isLockout) {
          return <Tag color="error">Refused</Tag>
        }
        return <Tag color="success">Requested</Tag>
      },
    },
    {
      title: 'Date',
      dataIndex: 'creationTime',
      key: 'creationTime',
      render: (creationTime) => <span>01/08/2023</span>,
    },
    {
      title: '',
      key: '',
      width: '100px',
      align: 'center',

      render: (_, record) => (
        <Space>
          <Button type="primary" ghost onClick={() => handleOpenOfferCampaign(record)}>
            View
          </Button>
        </Space>
      ),
    },
  ]

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Point List
          </Typography.Title>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card bordered={false}>
            {brand && brand.data && <Table columns={columns} dataSource={brand.data.items} rowKey="id" />}
          </Card>
        </Wrapper>
      </Container>
      <InfluencerOfferModal data={offerSelected} isOpen={isModalOfferOpen} onClose={handleCloseOfferCampaign} />
    </Layout>
  )
}

export default PointExchangeList
