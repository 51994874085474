import React from 'react'
import styled from 'styled-components'
import { Tabs, Space, Card, Table, Tag, Image, Button } from 'antd'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

const Applicants = () => {
  const campaignOffer = useSelector((state) => get(state, 'offer'))

  const columns: any = [
    {
      title: 'Influencer',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (record) => (
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <div>
            <Image
              style={{ borderRadius: '50%', overflow: 'hidden' }}
              width={60}
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            />
          </div>
          <div>
            <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>{record?.nickName}</h4>
            <div style={{ color: '#a8a8a8', fontSize: '0.825rem' }}>
              <span>{record?.gender === 'M' ? 'Nam' : 'Nữ'}</span>
              <span> / {record?.age}</span>
              {record?.province && <span>/ {record.province}</span>}
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Facebook',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (_, record) => (
        <div>
          <div style={{ fontSize: '1.25rem', lineHeight: 1 }}>{record?.facebook_user_follower || 0}</div>
          <div style={{ color: '#767676' }}>Followers</div>
        </div>
      ),
    },
    {
      title: 'Instagram',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (_, record) => (
        <div>
          <div style={{ fontSize: '1.25rem', lineHeight: 1 }}>{record?.instagram_user_follower || 0}</div>
          <div style={{ color: '#767676' }}>Followers</div>
        </div>
      ),
    },
    {
      title: 'Youtube',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (_, record) => (
        <div>
          <div style={{ fontSize: '1.25rem', lineHeight: 1 }}>
            {record.youtube_business_channel_subcriber_total || 0}
          </div>
          <div style={{ color: '#767676' }}>Subscriber</div>
        </div>
      ),
    },
    {
      title: 'Tiktok',
      dataIndex: 'influencer',
      key: 'influencer',
      render: (_, record) => (
        <div>
          <div style={{ fontSize: '1.25rem', lineHeight: 1 }}>{record?.tiktok_follower || 0}</div>
          <div style={{ color: '#767676' }}>Followers</div>
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => <Button type="primary">Select</Button>,
    },
  ]

  return (
    <div>
      <Space>
        <Button type="primary" ghost style={{ borderRadius: '20px' }}>
          All 100
        </Button>
        <Button style={{ borderRadius: '20px' }}>Applied 98</Button>
        <Button style={{ borderRadius: '20px' }}>Accepted 98</Button>
        <Button style={{ borderRadius: '20px' }}>Selected 20</Button>
        <Button style={{ borderRadius: '20px' }}>Canceled 2</Button>
      </Space>
      <br />
      <br />
      {campaignOffer && campaignOffer.data && <Table columns={columns} dataSource={campaignOffer.data.items} />}
    </div>
  )
}

export default Applicants
