import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Row, Col, Form, Input, Typography, Card, Upload, Button, Divider, Space, message } from 'antd'
import { LoadingOutlined, PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'

const Wrapper = styled.div`
  width: 100%;
`
const normFile = (e: any) => {
  console.log('Upload event:', e)
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`
const AddEditBrand = () => {
  const onFinish = (values: any) => {
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }
  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result as string))
    reader.readAsDataURL(img)
  }

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState<string>()

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false)
        setImageUrl(url)
      })
    }
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined rev={undefined} /> : <PlusOutlined rev={undefined} />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Title level={2} style={{ color: '#7645d9', margin: 0, padding: '0px 0 10px' }}>
            Nhà quảng cáo
          </Typography.Title>
          <div>
            <Link to="/advertisers">
              <Button type="primary" ghost icon={<ArrowLeftOutlined rev={undefined} />}>
                Quay lại
              </Button>
            </Link>
          </div>
        </div>
      </Header>
      <Container>
        <Wrapper>
          <Card>
            <Form
              name="basic"
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 11 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Row>
                <Col span={6}>
                  <Typography.Title level={4}>Thông tin </Typography.Title>
                </Col>
                <Col span={18}>
                  <Form.Item
                    name="thumbnail"
                    label="Ảnh đại diện"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    className="form-upload-item"
                  >
                    <Upload
                      name="avatar"
                      listType="picture-circle"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                  </Form.Item>
                  <Form.Item
                    label="Tên"
                    name="name"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Số điện thoại"
                    name="phone"
                    rules={[{ required: true, message: 'Please input your phone!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />
              <br />
              <div style={{ textAlign: 'center', paddingLeft: '105px' }}>
                <Space>
                  <Link to="/advertisers">
                    <Button htmlType="button" size="large" style={{ minWidth: '100px' }}>
                      Huỷ
                    </Button>
                  </Link>
                  <Button type="primary" htmlType="submit" size="large" style={{ minWidth: '100px' }}>
                    Lưu
                  </Button>
                </Space>
              </div>
            </Form>
          </Card>
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default AddEditBrand
