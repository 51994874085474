import React from 'react'
import { Link } from 'react-router-dom'
import {
  DashboardOutlined,
  UserOutlined,
  ClusterOutlined,
  StarOutlined,
  NotificationOutlined,
  CommentOutlined,
  PoundOutlined,
  MailOutlined,
} from '@ant-design/icons'

const menus = [
  {
    key: 'dashboard',
    icon: <DashboardOutlined rev={undefined} />,
    label: <Link to="/">Dashboard</Link>,
  },
  {
    key: 'campaigns',
    icon: <NotificationOutlined rev={undefined} />,
    label: <Link to="/campaigns">Campaign</Link>,
  },
  {
    key: 'influencers',
    icon: <StarOutlined rev={undefined} />,
    label: <Link to="/influencers">Influencer</Link>,
  },

  {
    key: 'offers',
    icon: <MailOutlined rev={undefined} />,
    label: <Link to="/offers">Offer</Link>,
  },
  {
    key: 'points',
    icon: <PoundOutlined rev={undefined} />,
    label: <Link to="/points">Point</Link>,
  },
  {
    key: 'brands',
    icon: <ClusterOutlined rev={undefined} />,
    label: <Link to="/brands">Brand</Link>,
    // children: [
    //   {
    //     key: 41,
    //     label: <Link to="/advertisers">Advertiser</Link>,
    //   },
    //   {
    //     key: 42,
    //     label: <Link to="/brands">Brand</Link>,
    //   },
    // ],
  },
  {
    key: 'edit-profile',
    icon: <UserOutlined rev={undefined} />,
    label: <Link to="/edit-profile">Profile</Link>,
    // children: [
    //   {
    //     key: 71,
    //     label: <Link to="/edit-profile">Edit profile</Link>,
    //   },
    // ],
  },
]

export default menus
