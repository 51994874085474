import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { getCategoryComboBoxApi } from 'api/category'

export const initialState: any = {
  isLoading: false,
  combobox: null,
  error: false,
}

export const getCategoryComboBox: any = createCallApiAsyncAction('category/getListCombobox', getCategoryComboBoxApi)

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getCategoryComboBox.fulfilled, (state, action) => {
      const { response }: any = action.payload
      return {
        ...state,
        isLoading: false,
        combobox: response.result || [],
      }
    })
    builder.addCase(getCategoryComboBox.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        combobox: null,
      }
    })
  },
})

export const categorySelector = (state: any) => state.category

const categoryReducer = categorySlice.reducer

export default categoryReducer
