import React, { useState } from 'react'
import styled from 'styled-components'
import { Typography, Row, Col, Form, Input, Select, Upload, Button, Radio } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

const { Option } = Select

const ProductInfo = () => {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={4}>
          <Typography.Title level={4}>Product</Typography.Title>
        </Col>
        <Col span={20}>
          <div>
            <Form.Item label="Sản phẩm" name="productName" rules={[{ required: true, message: 'Nhập tên sản phẩm' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Chi tiết sản phẩm"
              name="productDetail"
              rules={[{ required: true, message: 'Nhập sản phẩm chi tiết!' }]}
            >
              <Input.TextArea showCount maxLength={2000} style={{ height: 150 }} />
            </Form.Item>
            <Form.Item label="Điểm" name="point" rules={[{ required: true, message: 'Nhập điểm!' }]}>
              <Input />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProductInfo
