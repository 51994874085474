import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { unwrapResult } from '@reduxjs/toolkit'
import styled, { ThemeContext } from 'styled-components'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Card, Typography, Checkbox, Form, Input, Button, message } from 'antd'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { login } from 'state/auth'
import { getProfile } from 'state/profile'
import get from 'lodash/get'

import LayoutLogin from '../../components/LayoutLogin'

const StyledContainer = styled.div`
  width: 450px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
`

export default function Login() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const accessToken = useSelector((state) => get(state, 'auth.accessToken'))
  const [loading, setLoading] = useState(false)

  // useEffect(() => {
  //   if (accessToken) {
  //     history.push('/')
  //   }
  // }, [accessToken])

  const handleSubmitForm = (values: any) => {
    setLoading(true)
    dispatch(login({ ...values, accountType: 'advertiser' }))
      .then(unwrapResult)
      .then((response: any) => {
        setLoading(false)
        if (response && response.accessToken) {
          dispatch(getProfile({}))
          message.success('Đăng nhập thành công!')
          history.push('/')
        }
      })
  }

  return (
    <LayoutLogin>
      <StyledContainer>
        <Card>
          <div
            style={{
              lineHeight: 1,
              textAlign: 'center',
              margin: '0 0 10px 0',
            }}
          >
            <Link to="/">
              <img
                src="https://viralworks.com/assets/img/logo/logo_vertical_full.png"
                alt=""
                style={{
                  height: '40px',
                }}
              />
            </Link>
          </div>
          <Typography.Paragraph style={{ color: '#929292', textAlign: 'center' }}>
            Welcome to ViralWork Advertiser! <br />
            ViralWork Advertiser is available for advertiser members only.
          </Typography.Paragraph>
          <Typography.Title level={3}>Đăng nhập</Typography.Title>

          <Form
            layout="vertical"
            name="login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleSubmitForm}
          >
            <Form.Item
              name="userNameOrEmailAddress"
              rules={[{ required: true, message: 'Please input your Username!' }]}
            >
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input type="password" placeholder="Enter your password" />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <Form.Item name="rememberClient" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <a className="login-form-forgot" href="/forgot-password">
                Quên mật khẩu
              </a>
            </div>

            <Form.Item>
              <div>
                <Button
                  loading={loading}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  style={{ width: '100%' }}
                >
                  Đăng nhập
                </Button>
              </div>

              <br />
              <div>
                Bạn chưa có tài khoản? <Link to="/signup">Đăng ký ngay</Link>
              </div>
            </Form.Item>
          </Form>
        </Card>
      </StyledContainer>
    </LayoutLogin>
  )
}
