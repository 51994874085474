import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Tabs, Space, Card, Table, Tag, Image, Button } from 'antd'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import { useAppDispatch } from 'state'
import { getCampaignContentPost } from 'state/campaign'

const ContentPost = () => {
  const campaignOffer = useSelector((state) => get(state, 'offer'))
  const contentPost = useSelector((state) => get(state, 'campaign.contentPost'))
  const dispatch = useAppDispatch()
  let { campaignId }: any = useParams()

  useEffect(() => {
    dispatch(getCampaignContentPost({ campaignId }))
  }, [campaignId])

  const columns: any = [
    {
      title: 'Influencer',
      dataIndex: '',
      render: (_, record) => {
        const influ = campaignOffer?.data?.items?.find((item) => item.influencer.id == 2)
        return (
          <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
            <div>
              <Image
                style={{ borderRadius: '50%', overflow: 'hidden' }}
                width={60}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              />
            </div>
            <div>
              <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>{influ?.influencer?.nickName}</h4>
              <div style={{ color: '#a8a8a8', fontSize: '0.825rem' }}>
                <span>{influ.influencer?.gender === 'M' ? 'Nam' : 'Nữ'}</span>
                <span> / {influ.influencer?.age}</span>
                {influ.influencer?.province && <span> / {influ.influencer?.province}</span>}
              </div>
            </div>
          </div>
        )
      },
    },
    {
      title: 'Content Post',
      dataIndex: 'Submission',
      key: 'Submission',
      render: (_, record) => (
        <div>
          <div>
            <a href={`${record?.facebookUrl}`} target="_blank">
              {record?.facebookUrl}
            </a>
          </div>
        </div>
      ),
    },
    {
      title: 'Submit time',
      dataIndex: 'instagram_user_follower',
      key: 'instagram_user_follower',
      render: (_, record) => (
        <div>
          <span>2023.09.02 21:56:54</span>
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => <Button>Remove</Button>,
    },
  ]
  return <div>{contentPost?.items && <Table columns={columns} dataSource={contentPost?.items} />}</div>
}

export default ContentPost
