import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import RequestService from 'services/request'
import { Api } from 'config/api'
import get from 'lodash/get'

import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { getInfluencersApi, getInfluencerDetailApi } from 'api/influencer'

export const initialState: any = {
  isLoading: false,
  data: null,
  detail: null,
  combobox: null,
  error: false,
}

export const getInfluencers: any = createCallApiAsyncAction('influencer/getAll', getInfluencersApi)
export const getInfluencerDetail: any = createCallApiAsyncAction('influencer/getDetail', getInfluencerDetailApi)

export const influencerSlice = createSlice({
  name: 'influencer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInfluencers.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getInfluencers.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        data: get(response, 'result', null),
      }
    })
    builder.addCase(getInfluencers.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        data: null,
      }
    })
    builder.addCase(getInfluencerDetail.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getInfluencerDetail.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: false,
        detail: get(response, 'result.campaign', null),
      }
    })
    builder.addCase(getInfluencerDetail.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        detail: null,
      }
    })
  },
})

export const influencerSelector = (state: any) => state.influencer

const influencerReducer = influencerSlice.reducer

export default influencerReducer
