import React, { useRef } from 'react'
import styled from 'styled-components'
import * as Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Typography, Tabs, Space, Card, Table, Tag, Image, Button, Divider } from 'antd'
import { FileExcelOutlined, FilePdfOutlined } from '@ant-design/icons'
import { StyledHeaderCard, StyledBoxStat } from './styleds'
import { data, influencers } from './data'

const Report = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const options: Highcharts.Options = {
    title: {
      text: '',
      style: {
        display: 'none',
      },
    },
    xAxis: {
      categories: ['01/09', '02/09', '03/09', '04/09', '05/09', '06/09', '07/09', '08/09', '09/09'],
      title: {
        text: null,
      },
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    series: [
      {
        type: 'line',
        data: [400, 420, 500, 650, 630, 700, 1000, 800, 900],
      },
    ],
  }

  const columns: any = [
    {
      title: 'Reviewer',
      dataIndex: '',
      render: (_, record) => (
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <div>
            <Image style={{ borderRadius: '50%', overflow: 'hidden' }} width={60} src={record.profile} />
          </div>
          <div>
            <h4 style={{ fontWeight: 500, fontSize: '1rem' }}>{record.nickname}</h4>
            <div style={{ color: '#a8a8a8', fontSize: '0.825rem' }}>
              <span>{record.gender === 'M' ? 'Nam' : 'Nữ'}</span>
              <span> / {record.age}</span>
              <span>/{record.province}</span>
            </div>
            <div>
              <a href="https://www.tiktok.com/@jack.blee/video/7236359294059285766" target="_blank">
                https://www.tiktok.com/@jack.blee/video/7236359294059285766
              </a>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'View',
      dataIndex: 'facebook_user_follower',
      key: 'facebook_user_follower',
      render: (_, record) => (
        <div>
          <div>{record.facebook_user_follower}</div>
        </div>
      ),
    },
    {
      title: 'Like',
      dataIndex: 'instagram_user_follower',
      key: 'instagram_user_follower',
      render: (_, record) => (
        <div>
          <div>{record.instagram_user_follower}</div>
        </div>
      ),
    },
    {
      title: 'Share',
      dataIndex: 'youtube_business_channel_subcriber_total',
      key: 'youtube_business_channel_subcriber_total',
      render: (_, record) => (
        <div>
          <div>{record.youtube_business_channel_subcriber_total}</div>
        </div>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'tiktok_follower',
      key: 'tiktok_follower',
      render: (_, record) => (
        <div>
          <div>{record.tiktok_follower}</div>
        </div>
      ),
    },
    {
      title: '',
      key: 'action',
      render: (_, record) => <Button type="primary">View</Button>,
    },
  ]

  return (
    <div>
      <StyledHeaderCard>
        <Typography.Title level={4}>Overview</Typography.Title>
        <div>
          <Space>
            <span> Download Report </span>
            <Button style={{ border: '1px solid #107c41', color: '#107c41' }}>
              <FileExcelOutlined rev={undefined} />
              Download Excel
            </Button>
            <Button style={{ border: '1px solid #e33c11', color: '#e33c11' }}>
              <FilePdfOutlined rev={undefined} /> Download PDF
            </Button>
          </Space>
        </div>
      </StyledHeaderCard>
      <StyledHeaderCard>
        <StyledBoxStat className="active">
          <div className="title">Reaches</div>
          <div className="content">
            <div className="number">11.818</div>
            <div className="explain">Rate 0.96%</div>
          </div>
        </StyledBoxStat>
        <StyledBoxStat>
          <div className="title">Followers</div>
          <div className="content">
            <div className="number">2.1M</div>
            <div className="explain">Average 123.646</div>
          </div>
        </StyledBoxStat>
        <StyledBoxStat>
          <div className="title">Engagements</div>
          <div className="content">
            <div className="number">9,124</div>
            <div className="explain">Rate 0.6%</div>
          </div>
        </StyledBoxStat>
        <StyledBoxStat>
          <div className="title">Content</div>
          <div className="content">
            <div className="number">10</div>
          </div>
        </StyledBoxStat>
      </StyledHeaderCard>
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
      </div>
      <Divider />
      <div>
        <Typography.Title level={4}>Review</Typography.Title>
        <Table columns={columns} dataSource={influencers.data.list} />
      </div>
    </div>
  )
}

export default Report
