import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import RequestService from 'services/request'
import { Api } from 'config/api'
import get from 'lodash/get'
import { createCallApiAsyncAction } from 'store/middlewares/createAsyncAction'
import { getProfileApi, updateProfileApi } from 'api/profile'

export const initialState: any = {
  isLoading: false,
  data: null,
  error: false,
}

export const getProfile: any = createCallApiAsyncAction('profile/me', getProfileApi)
// export const updateProfile = createCallApiAsyncAction('profile/update', updateProfileApi)

export const updateProfile: any = createAsyncThunk('profile/update', async (payload: any, { rejectWithValue }) => {
  try {
    const res = await new RequestService().put(Api.UPDATE_PROFILE, payload)
    if (res && res.error) throw res

    return {
      profile: get(res, 'result.advertiser', null),
      error: null,
    }
  } catch (error) {
    return rejectWithValue({
      user: null,
      error: 'Please try again',
    })
  }
})

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileClear: () => ({
      ...initialState,
      isLoading: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      const { response } = action.payload
      return {
        ...state,
        isLoading: true,
        data: get(response, 'result.advertiser', null),
      }
    })
    builder.addCase(getProfile.rejected, (state) => {
      return {
        ...state,
        isLoading: false,
        data: null,
      }
    })
  },
})

export const profileSelector = (state: any) => state.profile

export default profileSlice.reducer

// Actions
export const { profileClear } = profileSlice.actions
