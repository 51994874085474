import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Divider, Button, Form, Card, Input, Typography, message } from 'antd'
import useI18n from 'hooks/useI18n'
import { useAppDispatch } from 'state'
import { register } from 'state/auth'
import LayoutLogin from '../../components/LayoutLogin'

const StyledContainer = styled.div`
  width: 450px;
  padding: 20px 0;
  display: flex;
`

export default function Signup() {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const handleSubmitForm = (values: any) => {
    console.log('Received values of form: ', values)

    // setLoading(true)
    dispatch(register(values))
      .then((response: any) => {
        setLoading(false)
        if (response?.payload?.error) {
          message.error(response?.payload?.error?.message)
        } else {
          message.success('Đăng ký thành công!')
          history.push('/login')
        }
      })
      .catch((errorMessage) => {
        message.error('Error, please try again.')
      })

    // try {
    //   const response: any = await dispatch(login(values))
    //   if (response.accessToken) {
    //     setLoading(false)
    //   }
    // } catch (err) {
    //   alert('error')
    // }
  }
  return (
    <LayoutLogin>
      <StyledContainer>
        <Card>
          <div
            style={{
              lineHeight: 1,
              textAlign: 'center',
              margin: '0 0 10px 0',
            }}
          >
            <Link to="/">
              <img
                src="https://viralworks.com/assets/img/logo/logo_vertical_full.png"
                alt=""
                style={{
                  height: '40px',
                }}
              />
            </Link>
          </div>
          <Typography.Paragraph style={{ color: '#929292', textAlign: 'center' }}>
            Welcome to ViralWork Advertiser! <br />
            ViralWork Advertiser is available for advertiser members only.
          </Typography.Paragraph>
          <Typography.Title level={3}>Đăng ký</Typography.Title>

          <Form
            layout="vertical"
            name="signup"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={handleSubmitForm}
          >
            <Form.Item name="name" rules={[{ required: true, message: 'Please input your Name!' }]}>
              <Input placeholder="Enter your name" />
            </Form.Item>
            <Form.Item name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
              <Input placeholder="Enter your email" />
            </Form.Item>
            <Form.Item name="mobilePhone" rules={[{ required: true, message: 'Please input your Phone!' }]}>
              <Input placeholder="Enter your phone" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input type="password" placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
              <div>
                <Button loading={loading} type="primary" htmlType="submit" className="login-form-button" block>
                  Đăng ký
                </Button>
              </div>
              <br />
              <div>
                Bạn đã có tài khoản? <Link to="/login">Đăng nhập</Link>
              </div>
            </Form.Item>
            {/* <Divider />
            <div
              style={{
                fontSize: '0.825rem',
              }}
            >
              Bằng cách tạo tài khoản hoặc đăng nhập, bạn đã đồng ý với các <a href="/dieu-khoan">điều khoản</a> của
              ViralWorks
            </div> */}
          </Form>
        </Card>
      </StyledContainer>
    </LayoutLogin>
  )
}
