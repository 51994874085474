import React, { useEffect, useState } from 'react'
import get from 'lodash/get'
import styled from 'styled-components'
import Container from 'components/Container'
import Layout from 'components/Layout'
import { Card, Popover, Modal, Space, Table, Image, Button, Typography, Divider } from 'antd'
import {
  HeartOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  InstagramOutlined,
  SearchOutlined,
  CheckCircleOutlined,
  DownOutlined,
} from '@ant-design/icons'
import { TickSquare, Heart } from 'iconsax-react'
import { useAppDispatch } from 'state'
import { getCampaigns } from 'state/campaign'
import { getInfluencers } from 'state/influencer'
import { useSelector } from 'react-redux'
import InfluencerOfferModal from '../InfluencerOfferModal'

import data from './data'
import {
  RowWrapper,
  ColSelect,
  ColProfile,
  ColAction,
  ColStat,
  ColStatWrapper,
  ColStatItem,
  ColStatNumber,
  ColStatLabel,
  ColProfileName,
  ColProfileCategory,
  StyledSearchWrapper,
} from './styleds'

const Wrapper = styled.div`
  width: 100%;
`

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients.bubblegum};
  width: 100%;
  padding: 32px;
`

const InfluencerList = () => {
  const dispatch = useAppDispatch()
  const influencer = useSelector((state) => get(state, 'influencer.data'))

  const [influencerSelected, setinfluencerSelected] = useState<any>()
  const [isModalOfferOpen, setModalOfferOpen] = useState(false)

  useEffect(() => {
    dispatch(getCampaigns({}))
    dispatch(getInfluencers({}))
  }, [])

  const handleOpenOfferCampaign = (influencer) => {
    setinfluencerSelected(influencer)
    setModalOfferOpen(true)
  }

  const handleCloseOfferCampaign = () => {
    setinfluencerSelected(null)
    setModalOfferOpen(false)
  }

  return (
    <Layout>
      <Header>
        <div style={{ display: 'flex', width: '100%', gap: '50px' }}>
          <div style={{ paddingTop: '10px' }}>
            <img src="/images/logo-search.svg" alt="" style={{ height: '50px' }} />
          </div>
          <StyledSearchWrapper>
            <input placeholder="Enter keyword or #hashtag" />
            <Button
              type="primary"
              size="large"
              icon={<SearchOutlined rev={undefined} />}
              style={{
                width: '130px',
                position: 'absolute',
                right: '10px',
                top: '10px',
                height: '55px',
                borderRadius: '10px',
                fontSize: '1.25rem',
              }}
            >
              Search
            </Button>
          </StyledSearchWrapper>
        </div>
      </Header>
      <Container>
        <div style={{ display: 'flex', gap: '20px', margin: '0 0 30px 0', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <Button
              shape="round"
              style={{
                height: '42px',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                background: '#f7f2ff',
              }}
            >
              <img src="https://partners.revu.net/static/_/img/common/ic_search_filter_pro.svg" alt="" /> See Pro Only
            </Button>
            <Button
              shape="round"
              style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px', background: '#fcf4f1' }}
            >
              🔥 High followers
            </Button>

            <Popover
              placement="bottom"
              style={{ borderRadius: '20px' }}
              content={
                <div>
                  <div>
                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto auto',
                        gap: '20px',
                        width: '350px',
                        padding: '10px',
                      }}
                    >
                      <Button ghost type="primary" shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                        Fashion
                      </Button>
                      <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                        Beauty
                      </Button>
                      <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                        Lifestyle
                      </Button>
                      <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                        Travel
                      </Button>
                      <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                        Food
                      </Button>
                      <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                        Baby
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <div style={{ textAlign: 'right', paddingBottom: '10px' }}>
                    <Space>
                      <Button>Reset</Button>
                      <Button type="primary">Apply</Button>
                    </Space>
                  </div>
                </div>
              }
              trigger="click"
            >
              <Button
                shape="round"
                style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px', background: '#f8f9fa' }}
              >
                🛍 Category <DownOutlined rev={undefined} style={{ fontSize: '10px', marginTop: '2px' }} />
              </Button>
            </Popover>
            <Popover
              placement="bottom"
              style={{ borderRadius: '20px' }}
              content={
                <div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto',
                      gap: '20px',
                      width: '350px',
                      padding: '10px',
                    }}
                  >
                    <Button ghost type="primary" shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      10K ~ 30K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      30K ~ 50K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      50K ~ 70K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      70K ~ 90K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      90K ~ 110K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      110K ~ 200K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      200K+
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ textAlign: 'right', paddingBottom: '10px' }}>
                    <Space>
                      <Button>Reset</Button>
                      <Button type="primary">Apply</Button>
                    </Space>
                  </div>
                </div>
              }
              trigger="click"
            >
              <Button
                shape="round"
                style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px', background: '#f8f9fa' }}
              >
                🌱 Followers <DownOutlined rev={undefined} style={{ fontSize: '10px', marginTop: '2px' }} />
              </Button>
            </Popover>
            <Popover
              placement="bottom"
              style={{ borderRadius: '20px' }}
              content={
                <div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto',
                      gap: '20px',
                      width: '350px',
                      padding: '10px',
                    }}
                  >
                    <Button ghost type="primary" shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      20y ~ 24y
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      24y ~ 28y
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      28y ~ 32y
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      32y ~ 36y
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      36y ~ 40y
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      40y ~ 50y
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      50y+
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      60y+
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ textAlign: 'right', paddingBottom: '10px' }}>
                    <Space>
                      <Button>Reset</Button>
                      <Button type="primary">Apply</Button>
                    </Space>
                  </div>
                </div>
              }
              trigger="click"
            >
              <Button
                shape="round"
                style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px', background: '#f8f9fa' }}
              >
                🌾 Age <DownOutlined rev={undefined} style={{ fontSize: '10px', marginTop: '2px' }} />
              </Button>
            </Popover>
            <Popover
              placement="bottom"
              style={{ borderRadius: '20px' }}
              content={
                <div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto',
                      gap: '20px',
                      width: '350px',
                      padding: '10px',
                    }}
                  >
                    <Button ghost type="primary" shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      100K ~ 500K
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      500K ~ 1M
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      1M ~ 2M
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      2M ~ 3M
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      3M ~ 4M
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      4M ~ 5M
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      5M+
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      100M+
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ textAlign: 'right', paddingBottom: '10px' }}>
                    <Space>
                      <Button>Reset</Button>
                      <Button type="primary">Apply</Button>
                    </Space>
                  </div>
                </div>
              }
              trigger="click"
            >
              <Button
                shape="round"
                style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px', background: '#f8f9fa' }}
              >
                ☂️ Ad Amount <DownOutlined rev={undefined} style={{ fontSize: '10px', marginTop: '2px' }} />
              </Button>
            </Popover>
            <Popover
              placement="bottom"
              style={{ borderRadius: '20px' }}
              content={
                <div>
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: 'auto auto',
                      gap: '20px',
                      width: '350px',
                      padding: '10px',
                    }}
                  >
                    <Button ghost type="primary" shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      Female
                    </Button>
                    <Button shape="round" icon={<CheckCircleOutlined rev={undefined} />}>
                      Male
                    </Button>
                  </div>
                  <Divider />
                  <div style={{ textAlign: 'right', paddingBottom: '10px' }}>
                    <Space>
                      <Button>Reset</Button>
                      <Button type="primary">Apply</Button>
                    </Space>
                  </div>
                </div>
              }
              trigger="click"
            >
              <Button
                shape="round"
                style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px', background: '#f8f9fa' }}
              >
                😎 Gender <DownOutlined rev={undefined} style={{ fontSize: '10px', marginTop: '2px' }} />
              </Button>
            </Popover>
          </div>
          <div>
            <Button shape="round" style={{ height: '42px', display: 'flex', alignItems: 'center', gap: '10px' }}>
              <img src="https://partners.revu.net/static/_/img/common/ic_search_filter_sub_btn.svg" alt="" />
              Advanced Filter
            </Button>
          </div>
        </div>
        <Wrapper>
          {influencer &&
            influencer.items.map((item, idx) => (
              <Card key={item.idx} style={{ marginBottom: '15px', borderRadius: '10px' }}>
                <RowWrapper>
                  <ColSelect>
                    <div>
                      {item.favorite ? (
                        <HeartOutlined rev={undefined} style={{ color: '#875ddd', fontSize: '20px' }} />
                      ) : (
                        <HeartOutlined rev={undefined} style={{ color: '#ccc', fontSize: '20px' }} />
                      )}
                    </div>
                  </ColSelect>
                  <ColProfile>
                    <div className="profileWrapper">
                      <div className="profileImage">
                        <Image
                          preview={false}
                          style={{ borderRadius: '50%', overflow: 'hidden' }}
                          width={90}
                          src={`/images/influencers/i${idx + 1}.jpg`}
                        />
                      </div>
                      <div className="profileName">
                        <ColProfileName>{item.nickName}</ColProfileName>
                        <ColProfileCategory>
                          <span>{item.gender === 'M' ? 'Nam' : 'Nữ'}</span>
                          <span> / {item.age}</span>
                          {item.province && <span> / {item.province}</span>}
                        </ColProfileCategory>
                        {/* <ColProfileCategory>{renderInterest(item.user_interests)}</ColProfileCategory> */}
                      </div>
                    </div>
                  </ColProfile>
                  <ColStat>
                    <ColStatWrapper>
                      <ColStatItem>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: '0 0 20px' }}>
                            <FacebookOutlined rev={undefined} style={{ fontSize: '15px', marginTop: '5px' }} />
                          </div>
                          <div>
                            <ColStatNumber>{item?.facebook_user_follower || 0}</ColStatNumber>
                            <ColStatLabel>Followers</ColStatLabel>
                          </div>
                        </div>
                      </ColStatItem>
                      <ColStatItem>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: '0 0 20px' }}>
                            <YoutubeOutlined rev={undefined} style={{ fontSize: '15px', marginTop: '5px' }} />
                          </div>
                          <div>
                            <ColStatNumber>{item.youtube_channel_subcriber_total || 0}</ColStatNumber>
                            <ColStatLabel>Subscribers</ColStatLabel>
                          </div>
                        </div>
                      </ColStatItem>
                      <ColStatItem>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: '0 0 20px' }}>
                            <InstagramOutlined rev={undefined} style={{ fontSize: '15px', marginTop: '5px' }} />
                          </div>
                          <div>
                            <ColStatNumber>{item.instagram_user_follower || 0}</ColStatNumber>
                            <ColStatLabel>Followers</ColStatLabel>
                          </div>
                        </div>
                      </ColStatItem>
                      <ColStatItem>
                        <ColStatNumber>Negotiation</ColStatNumber>
                        <ColStatLabel>Rewards(avg.) NTD 0</ColStatLabel>
                      </ColStatItem>
                    </ColStatWrapper>
                  </ColStat>
                  <ColAction>
                    <div>
                      <Button
                        onClick={() => handleOpenOfferCampaign(item)}
                        type="primary"
                        ghost
                        block
                        style={{ borderRadius: '8px', height: '50px', width: '130px', fontWeight: 'bold' }}
                      >
                        Offer
                      </Button>
                    </div>
                  </ColAction>
                </RowWrapper>
              </Card>
            ))}
          {/* <Card>
            <div>
              <Space>
                <Button type="primary">All</Button>
                <Button>Watchlist</Button>
              </Space>
            </div>
            <br />
            <Table columns={columns} dataSource={data.data.list} />
          </Card> */}
        </Wrapper>
      </Container>
      <InfluencerOfferModal
        influencer={influencerSelected}
        isOpen={isModalOfferOpen}
        onClose={handleCloseOfferCampaign}
      />
    </Layout>
  )
}

export default InfluencerList
